import * as React from "react";

import { Grid } from "@material-ui/core";
import { AssetEditSectionProps } from "../../containers/AssetEdit";
import { DraftTextInput } from "../../containers/DraftTextInput";
import { DraftYesNoNotSureInput } from "../../containers/DraftYesNoNotSureInput";
import { DraftArrayCheckboxInput } from "../../containers/DraftArrayCheckboxInput";

import { DIGITAL_STORAGE_SECURITY_LABELS, PAPER_STORAGE_SECURITY_LABELS } from "../../data";

// An explicit "none of the above" answer to augment (DIGITAL|PAPER)_STORAGE_SECURITY_LABELS
const noneOfTheAboveLabel = { value: "none", label: "None of the above" };

/**
 * Allow editing of storage and security section of asset
 */
export const StorageAndSecurityEdit = ({ asset }: AssetEditSectionProps) => {
  const isDigital = asset.storageFormat
    ? asset.storageFormat.indexOf("digital") !== -1
      ? "yes"
      : "no"
    : "";
  const isPhysical = asset.storageFormat
    ? asset.storageFormat.indexOf("paper") !== -1
      ? "yes"
      : "no"
    : "";
  return (
    <Grid container spacing={5} alignItems="center">
      <DraftTextInput
        label="Where is the asset stored?"
        helperText={
          "List all the places you store it — for example in Room 45, Greenwich " +
          "House; on a server in West Cambridge; in [name of team] Dropbox."
        }
        name="storageLocation"
        value={asset.storageLocation}
      />
      <DraftYesNoNotSureInput
        label="Is the asset stored in a digital format?"
        isBoolean={true}
        name="isDigital"
        value={isDigital}
        mapOnChangeToPatch={(value) => {
          if (value === "yes" && isDigital !== "yes") {
            // need to add 'digital' to storageFormat
            return { storageFormat: [...(asset.storageFormat || []), "digital"] };
          }
          if (value === "no" && isDigital === "yes") {
            // need to remove 'digital' from storageFormat
            return { storageFormat: (asset.storageFormat || []).filter((o) => o !== "digital") };
          }
          // nothing to do
          return false;
        }}
      />
      {isDigital === "yes" && (
        <DraftArrayCheckboxInput
          label="What security measures are followed to be keep the digital asset safe?"
          helperText="Tick all that apply."
          name="digitalStorageSecurity"
          options={[...DIGITAL_STORAGE_SECURITY_LABELS, noneOfTheAboveLabel]}
          values={asset.digitalStorageSecurity}
        />
      )}
      <DraftYesNoNotSureInput
        label="Is the asset stored in a physical format?"
        helperText="For example, paper."
        isBoolean={true}
        name="isPhysical"
        value={isPhysical}
        mapOnChangeToPatch={(value: string) => {
          if (value === "yes" && isPhysical !== "yes") {
            // need to add 'digital' to storageFormat
            return { storageFormat: [...(asset.storageFormat || []), "paper"] };
          }
          if (value === "no" && isPhysical === "yes") {
            // need to remove 'digital' from storageFormat
            return { storageFormat: (asset.storageFormat || []).filter((o) => o !== "paper") };
          }
          // nothing to do
          return false;
        }}
      />
      {isPhysical === "yes" && (
        <DraftArrayCheckboxInput
          label="What security measures are followed to keep the physical asset safe?"
          helperText="Tick all that apply."
          name="paperStorageSecurity"
          options={[...PAPER_STORAGE_SECURITY_LABELS, noneOfTheAboveLabel]}
          values={asset.paperStorageSecurity}
        />
      )}
    </Grid>
  );
};

export default StorageAndSecurityEdit;
