import * as React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      info: {},
      success: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
      },
      closeButton: {
        color: theme.palette.primary.contrastText,
      },
    }),
  { name: "Snackbars" }
);

export interface SnackbarProps {
  id?: string;
  message: string;
  type?: "success" | "info" | "error";
  autoClose?: number;
  closeButton?: boolean;
}

interface SnackbarsContextState {
  snackbars: SnackbarProps[];
  addSnackbar: (sb: SnackbarProps) => void;
}

// Holds snackbars context state for children of its provider
export const SnackbarsContext = React.createContext<SnackbarsContextState>({
  snackbars: [],
  addSnackbar: () => {},
});

// Convenience hook to access the current snackbars context state.
export const useSnackbars = () => React.useContext(SnackbarsContext);

/**
 * Snackbars component is actually a provider for children to easily add messages
 * and the MUI snackbars themselves
 */
export const Snackbars: React.FunctionComponent = ({ children }) => {
  // Hold snackbar list
  const [snackbars, dispatch] = React.useReducer(snackbarDispatcher, []);
  const classes = useStyles();

  // add snackbar
  const addSnackbar = React.useCallback(
    ({ message, type = "info", autoClose = 5000, closeButton = true }: SnackbarProps) => {
      // unique id
      const id = new Date().toJSON() + message;
      dispatch({ type: "ADD", payload: { id, message, type, autoClose, closeButton } });
    },
    [dispatch]
  );

  const removeSnackbar = React.useCallback(
    (id: string) => {
      dispatch({ type: "REMOVE", payload: id });
    },
    [dispatch]
  );

  // first snackbar (or null if none)
  const sb = snackbars.length ? snackbars[0] : null;
  return (
    <SnackbarsContext.Provider value={{ snackbars, addSnackbar }}>
      {sb && (
        <Snackbar
          open={true}
          key={sb.id}
          autoHideDuration={sb.autoClose || null}
          classes={{ root: classes[sb.type || "info"] }}
          onClose={() => {
            removeSnackbar(sb.id || "");
          }}
          message={sb.message}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          action={
            sb.closeButton ? (
              <IconButton
                onClick={() => {
                  removeSnackbar(sb.id || "");
                }}
                className={classes.closeButton}
              >
                <CloseIcon />
              </IconButton>
            ) : undefined
          }
          ContentProps={{ className: classes[sb.type || "info"] }}
        />
      )}
      {children}
    </SnackbarsContext.Provider>
  );
};

interface SnackbarAddAction {
  type: "ADD";
  payload: SnackbarProps;
}
interface SnackbarRemoveAction {
  type: "REMOVE";
  payload: string;
}
type SnackbarActions = SnackbarAddAction | SnackbarRemoveAction;

const snackbarDispatcher = (state: SnackbarProps[], action: SnackbarActions): SnackbarProps[] => {
  switch (action.type) {
    case "ADD":
      return [...state, action.payload];
    case "REMOVE":
      return state.filter((sb) => sb.id !== action.payload);
    default:
      throw new Error(`Unknown action: ${action}`);
  }
};

export default Snackbars;
