import * as React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  FormLabel,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";

import { useDraft, DraftPatch } from "../pages/AssetFormPage";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      radioGroup: {
        paddingLeft: theme.spacing(1.25),
      },
      radioLabels: {
        marginRight: theme.spacing(1.25),
        padding: theme.spacing(0.5, 3, 0.5, 0),
        border: ["1px", "solid", theme.palette.divider].join(" "),
      },
    }),
  { name: "DraftYesNoNotSureInput" }
);

export interface DraftYesNoNotSureInputProps {
  name: string;
  label: string;
  value: string | null;
  required?: boolean;
  helperText?: React.ReactNode;
  isBoolean?: boolean;
  mapOnChangeToPatch?: (value: string) => DraftPatch | false;
}

/**
 * A text input component for editing draft assets
 */
export const DraftYesNoNotSureInput = ({
  name,
  label,
  value,
  required = true,
  helperText = "",
  isBoolean = false,
  mapOnChangeToPatch,
}: DraftYesNoNotSureInputProps) => {
  const { patchDraft } = useDraft();
  const classes = useStyles();

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = value.trim();
    if (mapOnChangeToPatch !== undefined) {
      // custom patch creation
      const patch = mapOnChangeToPatch(newValue);
      if (patch !== false) {
        patchDraft(patch);
      }
    } else if (isBoolean) {
      // convert to boolean
      patchDraft({ [name]: newValue === "yes" });
    } else {
      // keep as string 'yes', 'no', 'not_sure' (or null if blank)
      patchDraft({ [name]: newValue === "" ? null : newValue });
    }
  };

  return (
    <Grid item xs={12}>
      <FormControl component="fieldset" fullWidth={true} required={required}>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} md={6}>
            <FormLabel component="legend">{label}</FormLabel>
            <FormHelperText>{helperText}</FormHelperText>
          </Grid>
          <Grid item xs={12} md={6}>
            <RadioGroup
              row={true}
              value={value}
              onChange={handleChange}
              className={classes.radioGroup}
            >
              <FormControlLabel
                control={<Radio color="primary" />}
                value="yes"
                label="Yes"
                className={classes.radioLabels}
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                value="no"
                label="No"
                className={classes.radioLabels}
              />
              {!isBoolean && (
                <FormControlLabel
                  control={<Radio color="primary" />}
                  value="not_sure"
                  label="Not sure"
                  className={classes.radioLabels}
                />
              )}
            </RadioGroup>
          </Grid>
        </Grid>
      </FormControl>
    </Grid>
  );
};

export default DraftYesNoNotSureInput;
