import * as React from "react";

import { Grid } from "@material-ui/core";
import { AssetEditSectionProps } from "../../containers/AssetEdit";
import { DraftYesNoNotSureInput } from "../../containers/DraftYesNoNotSureInput";
import { DraftArrayCheckboxInput } from "../../containers/DraftArrayCheckboxInput";
import { DraftTextInput } from "../../containers/DraftTextInput";
import { DraftRadioGroupInput } from "../../containers/DraftRadioGroupInput";

import { DATA_SUBJECT_LABELS, DATA_CATEGORY_LABELS, RETENTION_LABELS } from "../../data";

/**
 * Allow editing of personal data section of asset
 */
export const PersonalDataEdit = ({ asset }: AssetEditSectionProps) => (
  <Grid container spacing={5} alignItems="center">
    <DraftYesNoNotSureInput
      label="Does this asset hold any personal Data?"
      helperText="Personal data means any information about a living identifiable individual, for example, a student's name (or CRSid) and email address."
      isBoolean={true}
      name="personalData"
      value={asset.personalData ? "yes" : "no"}
    />
    {
      // Only show remaining fields if personal data is set.
      asset.personalData && (
        <>
          <DraftArrayCheckboxInput
            label="Who is this personal data about?"
            helperText="Tick all that apply."
            name="dataSubject"
            options={DATA_SUBJECT_LABELS}
            values={asset.dataSubject}
            halfWidth={true}
          />
          <DraftArrayCheckboxInput
            label="What kind of personal data is held?"
            helperText="Tick all that apply."
            name="dataCategory"
            options={DATA_CATEGORY_LABELS}
            values={asset.dataCategory}
            halfWidth={true}
          />
          <DraftYesNoNotSureInput
            label="Is the asset shared with someone outside the University?"
            helperText="This could be a company or an individual."
            name="recipientsOutsideUni"
            value={asset.recipientsOutsideUni}
          />
          {
            // only show following if "yes" was the answer
            asset.recipientsOutsideUni === "yes" && (
              <DraftTextInput
                label="Who is it shared with outside the University?"
                helperText="This could be a company or individual."
                name="recipientsOutsideUniDescription"
                value={asset.recipientsOutsideUniDescription}
                halfWidth={true}
                halfSpacer={true}
              />
            )
          }
          <DraftYesNoNotSureInput
            label="Is the asset shared outside of the EEA (European Economic Area)?"
            helperText={
              <>
                {"This could be a company or an individual. More information regards the EEA "}
                <a
                  href="https://www.gov.uk/eu-eea"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "inherit" }}
                >
                  can be found here
                </a>
                .
              </>
            }
            name="recipientsOutsideEea"
            value={asset.recipientsOutsideEea}
          />
          {
            // only show following if "yes" was the answer
            asset.recipientsOutsideEea === "yes" && (
              <DraftTextInput
                label="Who is it shared with outside of the EEA?"
                helperText="This could be a company or individual."
                name="recipientsOutsideEeaDescription"
                value={asset.recipientsOutsideEeaDescription}
                halfWidth={true}
                halfSpacer={true}
              />
            )
          }
          <DraftRadioGroupInput
            label="How long do you expect to keep this asset for?"
            options={RETENTION_LABELS}
            name="retention"
            value={asset.retention}
            helperText={
              <>
                {"If you're not sure how long records should be kept, and what " +
                  "to do when you no longer need them, see the "}
                <a
                  href="https://www.information-compliance.admin.cam.ac.uk/records-management"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "inherit" }}
                >
                  records management guidance
                </a>
                .
              </>
            }
            halfWidth={true}
            halfSpacer={true}
          />
        </>
      )
    }
  </Grid>
);

export default PersonalDataEdit;
