/**
 * Authentication support for the API.
 *
 * This module is a little complex but we try hard to hide complexity here so that users of the API
 * have a good time.
 *
 * Our API supports two forms of tokens: access and refresh. The access token is the token usually
 * used for authentication and is passed in the Authorization header. The access token is
 * short-lived by design and periodically needs updating via the refresh token.
 *
 * Most of the time you can just use the access token for API calls. If the token has expired, the
 * response has a HTTP status of 401 and we need to refresh the access token by passing the refresh
 * token to a special API endpoint. This will give us a new access token and, depending on server
 * configuration, a new refresh token. We can then try the API call again.
 *
 * Having two classes of token makes the common case of API access require no round-trip from the
 * server but still allows the server to control how long a client can continue to hold API
 * credentials without interacting with the server.
 *
 * The lifetime of the access token specifies the maximum time an API client can go between having
 * to re-check it's authentication credentials. The lifetime of the refresh token specified the
 * maximum time an API client can "go away" before returning to the API. It is intended that the
 * browser keep the refresh token in session storage. The refresh token expiry limits the maximum
 * time the user can be away from the site without having to log in again.
 *
 * Access and refresh tokens are obtained in the first place via a token "exchange" endpoint. The
 * client presents to the server an OAuth2 token from some acceptable social sign in provider which
 * the server converts into access and refresh tokens. In this manner we can potentially support
 * multiple sign-in options but keep the same access/refresh token logic.
 *
 * This module defines a React context, AuthenticationContext, which maintains internal state
 * allowing it to abstract this authentication flow described above from the user. As part of the
 * authentication process we fetch and cache the user's current profile so this is available on
 * first render if the user has previously logged in.
 *
 * The hooks exported by this module must only be used by components which are children of an
 * AuthenticationProvider element. The authentication state is managed by AuthenticationProvider on
 * behalf of the children.
 *
 * If credentials which are cached in the browser storage are used, this provider will also
 * asynchronously verify the cached credentials and sign the user out if they have expired or are
 * otherwise invalid. A flag indicating if the user has been signed out for this reason is
 * available named "authenticationFailed".
 *
 * The most useful value managed by AuthenticationProvider is a variant of the apiFetch() function.
 * This variant is wrapped so that is a) is authenticated with the current access token and b) will
 * re-try API calls if the response from the server indicates that the access token needs to be
 * refreshed.
 *
 * See the comments for AuthenticationState to see what other values are maintained by
 * AuthenticationProvider.
 */
import * as React from "react";
import { useSessionStorage } from "react-use";
import { Box, CircularProgress } from "@material-ui/core";
import { GoogleOAuthProvider, googleLogout, useGoogleLogin } from "@react-oauth/google";

import { Profile, SignInSettings, Tokens } from ".";

import unauthenticatedApiFetch from "./apiFetch";
import {
  PROFILES_USER_URL,
  TOKENS_SETTINGS_EXCHANGE_URL,
  TOKENS_EXCHANGE_GOOGLE_OAUTH2_URL,
  TOKENS_REFRESH_URL,
} from "./constants";
import EnableCookiesBanner from "../components/EnableCookiesBanner";

export enum AuthStatus {
  Error = "error",
  Unauthenticated = "unauthenticated",
  Authenticating = "authenticating",
  Authenticated = "authenticated",
}

/**
 * Authentication state available through AuthenticationContext.
 */
export interface AuthenticationState {
  authStatus: AuthStatus;

  /** Profile of signed in user. Undefined if no user is signed in. */
  profile?: Profile;

  /** Initiate a sign in. Undefined if the sign in infrastructure is not yet initialised. */
  signIn?: () => void;

  /**
   * Initiate a sign out. Note that this just logs the user out from this app but not Google. As
   * such the user will not be re-prompted for authentication when they sign in.
   */
  signOut: () => void;

  /**
   * Authenticated apiFetch wrapper. This wrapper attempts to be smart and re-try API calls which
   * result in 401 Unauthorized by first refreshing the access token. If refresh fails or if the
   * retried call still results in a 401 Unauthorized response, behaviour is as the unauthenticated
   * apiFetch function.
   */
  authenticatedApiFetch?: typeof unauthenticatedApiFetch;

  /**
   * True if the user was signed out because the access token could not be refreshed or if the
   * cached credentials are invalid. This is reset to false when the user initiates a sign in.
   */
  authenticationFailed: boolean;
}

/**
 * Authentication context. Holds authentication state for children of its provider.
 */
export const AuthenticationContext = React.createContext<AuthenticationState>({
  authStatus: AuthStatus.Unauthenticated,
  signOut: () => {},
  authenticationFailed: false,
});

/**
 * Convenience hook to access the current authentication state.
 */
export const useAuthenticationState = () => React.useContext(AuthenticationContext);

/**
 * Convenience hook to access just the authenticated API fetch function.
 */
export const useAuthenticatedAPIFetch = () => useAuthenticationState().authenticatedApiFetch;

/**
 * Authentication provider. Wrap any components which need authenticated API access in this
 * provider. Passes the current state and dispatch function down as a value.
 */
export const AuthenticationProvider: React.FunctionComponent = ({ children }) => (
  <AuthenticationContext.Provider value={useAuthenticationProviderValue()}>
    {children}
  </AuthenticationContext.Provider>
);

/**
 * Internal hook which manages authentication state for the AuthenticationProvider. Can take an
 * optional argument giving it the name of the browser sessionStorage key to use to persist the
 * state.
 */
const useAuthenticationProviderValue = (persistedStateKey: string = "authenticationState") => {
  const [authStatus, setAuthStatus] = React.useState(AuthStatus.Unauthenticated);

  // Flag indicating if authentication failed at some later date.
  const [authenticationFailed, setAuthenticationFailed] = React.useState(false);

  // State which is persisted in the browser's session state.
  interface PersistedState {
    // Access and refresh tokens.
    access?: string;
    refresh?: string;

    // User profile corresponding to those tokens.
    profile?: Profile;
  }

  // Persist the authentication state in the browser's sessionStorage.
  const [persistedState, setPersistedState] = useSessionStorage<PersistedState>(
    persistedStateKey,
    {}
  );

  // A function which will sign in a Google authenticated user to the application.
  const signInGoogleUser = React.useCallback(
    async (tokenResponse) => {
      setAuthenticationFailed(false);
      setAuthStatus(AuthStatus.Authenticating);
      try {
        const newTokens = await unauthenticatedApiFetch<Tokens>(
          TOKENS_EXCHANGE_GOOGLE_OAUTH2_URL,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );
        // Fetch the user's profile using the new token
        const newProfile = await unauthenticatedApiFetch<Profile>(PROFILES_USER_URL, {
          headers: { Authorization: `Bearer ${newTokens.access}` },
        });
        // If profile fetching succeeded, update the stored tokens and profile.
        setPersistedState({
          access: newTokens.access,
          refresh: newTokens.refresh,
          profile: newProfile,
        });

        setAuthStatus(AuthStatus.Authenticated);
      } catch (e) {
        setAuthStatus(AuthStatus.Unauthenticated);
        throw e;
      }
    },
    [setPersistedState]
  );

  React.useEffect(() => {
    if (persistedState.access) {
      setAuthStatus(AuthStatus.Authenticated);
    }
  }, [persistedState]);

  const signIn = useGoogleLogin({
    onSuccess: signInGoogleUser,
    onError: (errorResponse) => {
      console.error(errorResponse);
      setAuthStatus(AuthStatus.Error);
    },
    onNonOAuthError: (nonOAuthError) => {
      console.error(nonOAuthError);
      setAuthStatus(AuthStatus.Error);
    },
    hosted_domain: "cam.ac.uk",
  });

  const signOut = React.useCallback(() => {
    googleLogout();
    setPersistedState({});
    setAuthStatus(AuthStatus.Unauthenticated);
  }, [setPersistedState]);

  // Authenticated apiFetch wrapper. If an API call results in 401 Unauthorized, this will attempt
  // to refresh the access token and try again. If the call still fails, the failure is propagated
  // to the caller.
  const authenticatedApiFetch: typeof unauthenticatedApiFetch = React.useCallback(
    async (input: string | Request, init: RequestInit = {}, type?: string) => {
      // Extract the access and refresh tokens from the persisted state.
      const { access, refresh } = persistedState;

      // A function which will attempt to perform the fetch with the passed access token used for
      // authorisation. Returns a Promise just like unauthenticatedApiFetch() so the value should
      // be await-ed.
      const attemptFetch = (token: string | undefined) => {
        // Only attempt an authenticated fetch if token is defined.
        if (token) {
          return unauthenticatedApiFetch(
            input,
            {
              ...init,
              headers: { ...init.headers, Authorization: `Bearer ${token}` },
            },
            type
          );
        } else {
          return unauthenticatedApiFetch(input, init, type);
        }
      };

      try {
        // Attempt to perform the fetch with the current access token.
        return await attemptFetch(access);
      } catch (error) {
        // If the error was not 401 Unauthorized or we don't have a refresh token, re-throw.
        if (error.response.status !== 401 || !refresh) {
          throw error;
        }

        let newTokens: Tokens;
        try {
          // Otherwise, attempt to refresh token.
          newTokens = await unauthenticatedApiFetch<Tokens>(TOKENS_REFRESH_URL, {
            body: JSON.stringify({ refresh }),
            method: "POST",
          });
        } catch (refreshError) {
          // If we fail to refresh, sign out and re-throw error.
          console.error("Failed to refresh token.");
          setAuthenticationFailed(true);
          setAuthStatus(AuthStatus.Unauthenticated);
          signOut();
          throw refreshError;
        }

        // The response may or may not include a refresh token but should always contain an access
        // token.
        setPersistedState({
          ...persistedState,
          access: newTokens.access,
          refresh: newTokens.refresh || refresh,
        });

        // After all this token refreshing, re-try the call with the new access token.
        return await attemptFetch(newTokens.access);
      }
    },
    [persistedState, setPersistedState, signOut]
  );

  return {
    authenticatedApiFetch,
    authenticationFailed,
    authStatus,
    profile: persistedState.profile,
    signIn,
    signOut,
  };
};

export const LoadGoogleOAuthProvider: React.FunctionComponent = ({ children }) => {
  const [isIdLoading, setIsIdLoading] = React.useState(true);
  const [isScriptLoading, setIsScriptLoading] = React.useState(true);
  const [clientId, setClientId] = React.useState("no-client-id");

  const [failed, setFailed] = React.useState(false);

  React.useEffect(() => {
    const fetchClientId = async () => {
      const { googleClientId } = (await unauthenticatedApiFetch(
        TOKENS_SETTINGS_EXCHANGE_URL
      )) as SignInSettings;
      setClientId(googleClientId);
      setIsIdLoading(false);
    };

    fetchClientId().catch((error) => {
      console.error(error);
      setFailed(true);
    });
  }, []);

  return (
    <GoogleOAuthProvider
      clientId={clientId}
      onScriptLoadSuccess={() => setIsScriptLoading(false)}
      onScriptLoadError={() => setFailed(true)}
    >
      {failed ? (
        <EnableCookiesBanner />
      ) : isIdLoading || isScriptLoading ? (
        <Box p={2} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </GoogleOAuthProvider>
  );
};
