import * as React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import PageSection from "./PageSection";

export interface FlashBannerProps {
  /**
   * Colour of flash banner.
   */
  color?: "default" | "primary" | "secondary" | "error";
}

/**
 * Page banner used to display a message to the user.
 */
export const FlashBanner: React.FunctionComponent<FlashBannerProps> = ({
  color = "default",
  children,
  ...props
}) => {
  const classes = useStyles(props);
  const rootClassName = [classes.root];

  if (color === "default") {
    rootClassName.push(classes.default);
  }
  if (color === "primary") {
    rootClassName.push(classes.primary);
  }
  if (color === "secondary") {
    rootClassName.push(classes.secondary);
  }
  if (color === "error") {
    rootClassName.push(classes.error);
  }

  return <PageSection classes={{ root: rootClassName.join(" ") }}>{children}</PageSection>;
};

export default FlashBanner;

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      /** Styles applied to root element. */
      root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },

      /** Styles applied if color="default". */
      default: {
        backgroundColor: theme.palette.background.default,
      },

      /** Styles applied if color="primary". */
      primary: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
      },

      /** Styles applied if color="secondary". */
      secondary: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.contrastText,
      },

      /** Styles applied if color="error". */
      error: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.contrastText,
      },
    }),
  { name: "FlashBanner" }
);
