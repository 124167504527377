import * as React from "react";

import { Grid } from "@material-ui/core";
import { AssetEditSectionProps } from "../../containers/AssetEdit";
import { DraftArrayCheckboxInput } from "../../containers/DraftArrayCheckboxInput";
import { DraftTextInput } from "../../containers/DraftTextInput";

import { RISK_TYPE_LABELS } from "../../data";

// An explicit "none of the above" answer to augment RISK_TYPE_LABELS
const noneOfTheAboveLabel = { value: "none", label: "None of the above" };

/**
 * Allow editing of risks section of asset
 */
export const RisksEdit = ({ asset }: AssetEditSectionProps) => (
  <Grid container spacing={5} alignItems="center">
    <DraftArrayCheckboxInput
      label="What are the risks if the information in this asset were lost or compromised?"
      helperText="Tick all that apply."
      name="riskType"
      options={[...RISK_TYPE_LABELS, noneOfTheAboveLabel]}
      values={asset.riskType}
    />
    <DraftTextInput
      required={false}
      label="Supporting information"
      helperText={
        "Please tell us a bit more detail about why these risks exist and how it " +
        "could impact your institution or the University"
      }
      name="riskTypeAdditional"
      value={asset.riskTypeAdditional}
    />
  </Grid>
);

export default RisksEdit;
