/**
 * Support for lookup API
 */

import * as React from "react";
import { useSessionStorage } from "react-use";

import {
  APIError,
  Institution,
  LookupInstsResponse,
  useAuthenticatedAPIFetch,
  Person,
  LookupPeopleResponse,
  appendQuery,
} from ".";
import { LOOKUP_INSTS_URL, LOOKUP_PEOPLE_URL, LOOKUP_PEOPLE_LIMIT } from "./constants";

/**
 * Institutions component of LookupState
 */
export type LookupInstitutions = { [instId: string]: string };
/**
 * Lookup state available through LookupContext.
 */
export interface LookupState {
  /* whether state is loading */
  isLoading: boolean;

  /* fetched institutions */
  institutions: LookupInstitutions;

  /* Function that looks for Institutions in cache */
  getInstName: (instid: string) => string | null;

  /* Function that looks for People given partial string */
  getPeople: (text: string) => Promise<Person[]>;

  /* Function that looks for a Person given their id */
  getPerson: (id: string) => Promise<Person | null>;

  /* Last API error */
  error?: APIError;
}

// Initial state - no institutions
const INITIAL_CONTEXT_STATE = {
  isLoading: false,
  institutions: {},
  getInstName: () => null,
  getPeople: () => {
    return Promise.resolve([]);
  },
  getPerson: () => {
    return Promise.resolve(null);
  },
};

/**
 * Lookup context. Holds lookup state for children of its provider.
 */
export const LookupContext = React.createContext<LookupState>(INITIAL_CONTEXT_STATE);

/**
 * Convenience hook to access the current lookup state.
 */
export const useLookupState = () => React.useContext(LookupContext);

/**
 * Convenience hook to access just the institution name lookup function.
 */
export const useLookupInstName = () => useLookupState().getInstName;

/**
 * Convenience hook to access just the people lookup function.
 */
export const useLookupPeople = () => useLookupState().getPeople;

/**
 * Convenience hook to access just the people lookup function.
 */
export const useLookupPerson = () => useLookupState().getPerson;

/**
 * Lookup provider. Wrap any components which need lookup API access in this
 * provider. Passes the current state and dispatch function down as a value.
 */
export const LookupProvider: React.FunctionComponent = ({ children }) => (
  <LookupContext.Provider value={useLookupProviderValue()}>{children}</LookupContext.Provider>
);

/**
 * Internal hook which manages lookup state for the LookupProvider. Can take an
 * optional argument giving it the name of the browser sessionStorage key to use
 * to persist the institution names.
 */
const useLookupProviderValue = (persistedInstsKey: string = "lookupInsts") => {
  // Persist the institutions list in session storage
  const [persistedInsts, setPersistedInsts] = useSessionStorage<LookupInstitutions>(
    persistedInstsKey,
    {}
  );
  // Use persisted insts if available
  const initialState = Object.keys(persistedInsts).length
    ? { ...INITIAL_CONTEXT_STATE, institutions: persistedInsts }
    : INITIAL_CONTEXT_STATE;

  const [state, dispatch] = React.useReducer(contextReducer, initialState);
  const authAPI = useAuthenticatedAPIFetch();

  // Setting isLoading triggers load
  const { isLoading, institutions } = state;
  React.useEffect(() => {
    if (isLoading) {
      // Api call wrapper
      const lookupApiCall = (): Promise<LookupInstsResponse> => {
        if (!authAPI) {
          throw new Error("No authentication");
        }
        return authAPI(LOOKUP_INSTS_URL);
      };
      lookupApiCall()
        .then((response: LookupInstsResponse) => {
          const insts = instsFromResults(response.results);
          // add insts to state
          dispatch({ type: "ADD_INSTS", payload: insts });
          // cache insts in session storage
          setPersistedInsts(insts);
        })
        .catch((e) => {
          dispatch({ type: "SET_ERROR", payload: e });
        });
    }
  }, [isLoading, institutions, authAPI, setPersistedInsts]);

  // Retrieve individual institution from instid (possibly null until loaded)
  const getInstName = React.useCallback(
    (instid: string): string | null => {
      dispatch({ type: "LOAD_INSTS" });
      return instid in state.institutions ? state.institutions[instid] : null;
    },
    [state.institutions]
  );

  // Retrieve a list of people matching partial string
  const getPeople = React.useCallback(
    async (text: string): Promise<Person[]> => {
      if (text.length < 2) {
        return Promise.resolve([]);
      }
      if (!authAPI) {
        throw new Error("No authentication");
      }
      try {
        const response: LookupPeopleResponse = await authAPI(
          appendQuery(LOOKUP_PEOPLE_URL, {
            limit: LOOKUP_PEOPLE_LIMIT,
            query: text,
          })
        );
        return Promise.resolve(response.results);
      } catch (e) {
        dispatch({ type: "SET_ERROR", payload: e });
        return Promise.reject(e);
      }
    },
    [authAPI]
  );

  // Retrieve an individual person given their id
  const getPerson = React.useCallback(
    async (id: string): Promise<Person | null> => {
      if (id === "") {
        return Promise.resolve(null);
      }
      if (!authAPI) {
        throw new Error("No authentication");
      }
      try {
        const response: Person = await authAPI(`${LOOKUP_PEOPLE_URL}${id}`);
        return Promise.resolve(response);
      } catch (e) {
        dispatch({ type: "SET_ERROR", payload: e });
        return Promise.reject(e);
      }
    },
    [authAPI]
  );

  return {
    ...state,
    getInstName,
    getPeople,
    getPerson,
  };
};

/* Actions for following contextReducer */
interface LookupInstsLoadAction {
  type: "LOAD_INSTS";
}
interface LookupInstsAddAction {
  type: "ADD_INSTS";
  payload: LookupInstitutions;
}
interface LookupErrorAction {
  type: "SET_ERROR";
  payload: APIError;
}
type LookupActions = LookupInstsLoadAction | LookupInstsAddAction | LookupErrorAction;

/* Lookup Context Reducer */
const contextReducer = (state: LookupState, action: LookupActions): LookupState => {
  switch (action.type) {
    case "LOAD_INSTS":
      // Don't set loading if currently loading or institutions already loaded
      if (state.isLoading || Object.keys(state.institutions).length) {
        return state;
      }
      return { ...state, isLoading: true };
    case "ADD_INSTS":
      // Remember retrieved institution
      return { ...state, institutions: action.payload, isLoading: false };
    case "SET_ERROR":
      return { ...state, error: action.payload, isLoading: false };
    default:
      throw new Error(`Unknown action: ${action}`);
  }
};

/* Convert the results list from the API response to a map of instids to names */
const instsFromResults = (results: Institution[]): LookupInstitutions => {
  return results.reduce((map: LookupInstitutions, inst: Institution) => {
    map[inst.instid] = inst.name;
    return map;
  }, {});
};
