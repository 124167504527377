import * as React from "react";

import { Grid } from "@material-ui/core";
import { AssetViewSectionProps } from "../AssetView";
import { StaticAnswer } from "./StaticAnswer";
import { MultiSelectView } from "./MultiSelectView";
import { ReadOnlyLabelValue } from "./ReadOnlyLabelValue";

import { DATA_SUBJECT_LABELS, DATA_CATEGORY_LABELS, RETENTION_LABELS } from "../../data";

/**
 * Display personal data section of asset view
 */
export const PersonalDataView = ({ asset }: AssetViewSectionProps) => (
  <Grid container spacing={5} alignItems="center">
    <StaticAnswer
      label="Does this asset hold any personal Data?"
      value={asset.personalData ? "yes" : "no"}
    />
    {
      // Only show remaining fields if personal data is set.
      asset.personalData && (
        <>
          <MultiSelectView
            label="Who is this personal data about?"
            options={DATA_SUBJECT_LABELS}
            values={asset.dataSubject}
          />
          <MultiSelectView
            label="What kind of personal data is held?"
            options={DATA_CATEGORY_LABELS}
            values={asset.dataCategory}
          />
          <StaticAnswer
            label="Is the asset shared with someone outside the University?"
            value={asset.recipientsOutsideUni}
          />
          {
            // only show following if "yes" was the answer
            asset.recipientsOutsideUni === "yes" && (
              <ReadOnlyLabelValue
                label="Who is it shared with outside the University?"
                value={asset.recipientsOutsideUniDescription || ""}
              />
            )
          }
          <StaticAnswer
            label="Is the asset shared outside of the EEA (European Economic Area)?"
            value={asset.recipientsOutsideEea}
          />
          {
            // only show following if "yes" was the answer
            asset.recipientsOutsideEea === "yes" && (
              <ReadOnlyLabelValue
                label="Who is it shared with outside of the EEA?"
                value={asset.recipientsOutsideEeaDescription || ""}
              />
            )
          }
          <StaticAnswer
            label="How long do you expect to keep this asset for?"
            options={RETENTION_LABELS}
            value={asset.retention}
          />
        </>
      )
    }
  </Grid>
);

export default PersonalDataView;
