import * as React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  FormLabel,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";

import { useDraft } from "../pages/AssetFormPage";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      twoColumnGroup: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingTop: theme.spacing(1),
      },
      twoColumnLabel: {
        marginRight: theme.spacing(-4),
        borderBottom: ["1px", "solid", theme.palette.divider].join(" "),
      },
    }),
  { name: "DraftArrayCheckboxInput" }
);

export interface DraftArrayCheckboxInputProps {
  name: string;
  label: string;
  values: string[] | null;
  options: { value: string; label: string }[];
  required?: boolean;
  helperText?: React.ReactNode;
  halfWidth?: boolean;
  clearValue?: string;
}

/**
 * A text input component for editing draft assets
 */
export const DraftArrayCheckboxInput = ({
  name,
  label,
  values,
  options,
  required = true,
  helperText = "",
  halfWidth = false,
  clearValue = "none",
}: DraftArrayCheckboxInputProps) => {
  const { patchDraft } = useDraft();
  const classes = useStyles();

  const isSelected = (option: string): boolean => (values || []).indexOf(option) !== -1;

  const handleChange = (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    // being careful that values may initially be null
    const currentValues = values || [];
    if (value === clearValue && checked) {
      // selecting the clearValue will unselected everything but it
      patchDraft({ [name]: [value] });
    } else if (checked && !isSelected(value)) {
      // option (value) needs adding to values (as well as filtering out clearValue)
      patchDraft({ [name]: [...currentValues.filter((o) => o !== clearValue), value] });
    } else if (!checked && isSelected(value)) {
      // option (value) needs removing from values
      patchDraft({ [name]: currentValues.filter((o) => o !== value) });
    }
  };

  return (
    <Grid item xs={12}>
      <FormControl component="fieldset" fullWidth={true} required={required}>
        <FormLabel component="legend">{label}</FormLabel>
        <FormHelperText>{helperText}</FormHelperText>
        <FormGroup className={classes.twoColumnGroup}>
          {options.map((option) => (
            <Grid
              key={option.value}
              item
              xs={12}
              md={halfWidth ? 6 : 12}
              className={classes.twoColumnLabel}
            >
              <FormControlLabel
                value={option.value}
                label={option.label}
                checked={isSelected(option.value)}
                control={<Checkbox color="primary" onChange={handleChange} />}
              />
            </Grid>
          ))}
        </FormGroup>
      </FormControl>
    </Grid>
  );
};

export default DraftArrayCheckboxInput;
