// Base URL for API.
export const BASE_URL = window.location.protocol + "//" + window.location.host + "/api";

// Endpoint locations
export const PROFILES_USER_URL = `${BASE_URL}/profiles/user/`;

const TOKENS_BASE_URL = `${BASE_URL}/tokens/`;
export const TOKENS_SETTINGS_EXCHANGE_URL = `${TOKENS_BASE_URL}settings/exchange/`;
export const TOKENS_REFRESH_URL = `${TOKENS_BASE_URL}refresh/`;
export const TOKENS_EXCHANGE_URL = `${TOKENS_BASE_URL}exchange/`;
export const TOKENS_EXCHANGE_GOOGLE_OAUTH2_URL = `${TOKENS_EXCHANGE_URL}google-oauth2/`;

const LOOKUP_BASE_URL = `${BASE_URL}/lookup/`;
export const LOOKUP_INSTS_URL = `${LOOKUP_BASE_URL}institutions/`;
export const LOOKUP_PEOPLE_URL = `${LOOKUP_BASE_URL}people/`;
export const LOOKUP_PEOPLE_LIMIT = 10;

export const ASSETS_URL = `${BASE_URL}/assets/`;

export const DUMP_URL = `${BASE_URL}/dump/`;

// Content Type used for JSON requests
export const JSON_CONTENT_TYPE = "application/json";
