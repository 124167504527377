import { ReactNode } from "react";

import help from "./help";
import feedback from "./feedback";

interface StaticPageProps {
  title: string;
  content: ReactNode;
}

export const pages: { [page: string]: StaticPageProps } = { help, feedback };
