import * as React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      section: {
        // Before the first section element inside a container, reset section CSS counter to zero.
        "&:first-child::before": {
          counterReset: "form-section",
        },
        // Each section increments the section counter.
        counterIncrement: "form-section",
        position: "relative",
      },
      heading: {
        fontFamily: "Roboto Mono, monospace",
        fontWeight: theme.typography.body1.fontWeight,
        minHeight: 48,
        height: 48, // required due to IE11 bug
        display: "flex",
        alignItems: "center",
        backgroundColor: "rgba(0, 177, 193, 0.08)",
        borderBottom: "2px solid rgba(0, 177, 193, 0.17)",
        paddingLeft: theme.spacing(1),
        margin: 0,

        "&::before": {
          content: 'counter(form-section) "."',
          textAlign: "right",
          display: "inline-block",
          width: theme.spacing(8),
          paddingRight: theme.spacing(1),
          boxSizing: "border-box",
        },
      },
      title: {
        flex: "1 1 auto",
      },
      indicator: {
        paddingRight: theme.spacing(2),
      },
      content: {
        padding: theme.spacing(3, 9, 6, 9),
      },
    }),
  { name: "AssetSection" }
);

export interface AssetSectionProps {
  title: string;
  indicator?: React.ReactNode;
}

/**
 * Section of asset information with title and content
 */
export const AssetSection: React.FunctionComponent<AssetSectionProps> = ({
  title,
  indicator,
  children,
}) => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <h3 className={classes.heading}>
        <div className={classes.title}>{title}</div>
        {indicator ? <div className={classes.indicator}>{indicator}</div> : null}
      </h3>
      <div className={classes.content}>{children}</div>
    </section>
  );
};

export default AssetSection;
