import * as React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableRow, TableCell, Tooltip, IconButton, Menu, MenuItem } from "@material-ui/core";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import MoreVert from "@material-ui/icons/MoreVert";

import TimeAgo from "react-timeago";

import { useHistory } from "react-router-dom";

import { AssetSummary } from "./AssetTableBody";
import InstitutionName from "./InstitutionName";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      tableRow: {
        height: "57px",
        cursor: "pointer",
      },
      tableCell: {
        fontSize: "13px",
        paddingTop: "4px",
        paddingBottom: "4px",
      },
      status: {
        fontWeight: theme.typography.h6.fontWeight,
        padding: theme.spacing(0.5),
        textAlign: "center",
        borderRadius: theme.spacing(0.5),
      },
      statusComplete: {
        backgroundColor: "#7ed321",
        color: theme.palette.primary.contrastText,
      },
      statusIncomplete: {
        backgroundColor: "#f5a623",
        color: theme.palette.primary.contrastText,
      },
      privateIcon: {
        color: "#757575",
      },
    }),
  { name: "AssetListItem" }
);

export interface AssetListItemProps {
  asset: AssetSummary;
  onDelete: (id: string) => void;
}

/**
 * Display an individual asset summary row with completion 'label' and privacy icon.
 * Optional action menu and click behaviour dependant upon the allowedMethods
 * permissions of the asset
 */
export const AssetListItem = ({ asset, onDelete }: AssetListItemProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { id, name, isComplete, instName, isPrivate, updatedAt, allowedMethods } = asset;

  const canEdit = allowedMethods && allowedMethods.indexOf("PUT") !== -1;
  const canDelete = allowedMethods && allowedMethods.indexOf("DELETE") !== -1;

  // override TableCell with a custom styled version with click handler
  const EditCell: React.FunctionComponent = ({ children }) => (
    <TableCell className={classes.tableCell} onClick={viewOrEditAsset}>
      {children}
    </TableCell>
  );

  const viewOrEditAsset = () => {
    history.push(`/asset/${id}${canEdit ? "/edit" : ""}`);
  };

  const AssetStatus = () => (
    <div
      className={[
        classes.status,
        isComplete ? classes.statusComplete : classes.statusIncomplete,
      ].join(" ")}
    >
      {isComplete ? "Complete" : "Incomplete"}
    </div>
  );

  const PrivateIcon = () => {
    // this has three return values since "isPrivate" may be null/not a Boolean
    if (isPrivate === null) {
      return null;
    }
    return (
      <span className={classes.privateIcon}>
        <Tooltip title={isPrivate ? "Private" : "Public"}>
          {isPrivate ? <VisibilityOff /> : <Visibility />}
        </Tooltip>
      </span>
    );
  };

  const MoreMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const closeMenu = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
        >
          <MoreVert />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
          {canEdit ? (
            <MenuItem
              onClick={() => {
                closeMenu();
                viewOrEditAsset();
              }}
            >
              Edit
            </MenuItem>
          ) : null}
          {canDelete ? (
            <MenuItem
              onClick={() => {
                closeMenu();
                onDelete(id);
              }}
            >
              Delete
            </MenuItem>
          ) : null}
        </Menu>
      </div>
    );
  };

  return (
    <TableRow className={classes.tableRow} hover={true}>
      <EditCell>{name}</EditCell>
      <EditCell>
        <AssetStatus />
      </EditCell>
      <EditCell>
        <InstitutionName instName={instName} />
      </EditCell>
      <EditCell>
        <PrivateIcon />
      </EditCell>
      <EditCell>
        <TimeAgo date={new Date(updatedAt)} />
      </EditCell>
      <TableCell>{canEdit || canDelete ? <MoreMenu /> : null}</TableCell>
    </TableRow>
  );
};

export default AssetListItem;
