import * as React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import InProgressIcon from "@material-ui/icons/MoreHoriz";
import CompleteIcon from "@material-ui/icons/Check";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      common: {
        verticalAlign: "middle",
        borderRadius: "50%",
        color: theme.palette.primary.contrastText,
      },
      inProgress: {
        backgroundColor: "#f5a623",
      },
      complete: {
        backgroundColor: "#7ed321",
      },
    }),
  { name: "CompleteIndicator" }
);

export interface CompleteIndicatorProps {
  isComplete: boolean;
}

/**
 * Table heading with help icon and tooltip description
 */
export const CompleteIndicator = ({ isComplete }: CompleteIndicatorProps) => {
  const classes = useStyles();

  return isComplete ? (
    <CompleteIcon className={[classes.common, classes.complete].join(" ")} />
  ) : (
    <InProgressIcon className={[classes.common, classes.inProgress].join(" ")} />
  );
};

export default CompleteIndicator;
