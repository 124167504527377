import * as React from "react";

import { CircularProgress, Box } from "@material-ui/core";
import VisibilitySensor from "react-visibility-sensor";

export interface GetMoreAssetsProps {
  onNextPage: () => void;
  isLoading: boolean;
}

/**
 * Component that shows a loading indicator and triggers a next page call (if not
 * already loading) when it becomes visible in the browser
 */
export const GetMoreAssets = ({ onNextPage, isLoading }: GetMoreAssetsProps) => {
  const onChange = (isVisible: boolean) => {
    if (isVisible && !isLoading) {
      onNextPage();
    }
  };

  return (
    // Note: VisibilitySensor must have a rendered HTML child element.
    //  Box is a div, so this is fine
    <VisibilitySensor active={!isLoading} onChange={onChange}>
      <Box textAlign="center" py={2}>
        {isLoading && <CircularProgress />}
      </Box>
    </VisibilitySensor>
  );
};

export default GetMoreAssets;
