import * as React from "react";
import { Typography } from "@material-ui/core";

import { useAuthenticationState } from "../api";
import FlashBanner from "../components/FlashBanner";

/**
 * Display banner indicating if the user was signed out due to inactivity.
 */
export const AuthenticationFlash: React.FunctionComponent = () => {
  const { authenticationFailed } = useAuthenticationState();

  if (authenticationFailed) {
    return (
      <FlashBanner color="error">
        <Typography variant="body1">
          You were signed out. You must sign in again to continue.
        </Typography>
      </FlashBanner>
    );
  } else {
    return null;
  }
};

export default AuthenticationFlash;
