import * as React from "react";

import { useHistory } from "react-router-dom";

import { AssetHeader, HeaderButton } from "./AssetHeader";

export interface AssetEditHeaderProps {
  name: string;
  isCreate: boolean;
  onSave: () => void;
  onDelete?: () => void;
}

export const AssetEditHeader = ({ name, isCreate, onSave, onDelete }: AssetEditHeaderProps) => {
  const history = useHistory();
  const buttons = [
    <HeaderButton key="cancel_button" onClick={history.goBack}>
      Cancel
    </HeaderButton>,
    <HeaderButton key="save_button" color="secondary" onClick={onSave}>
      Save Entry
    </HeaderButton>,
  ];
  if (!isCreate) {
    buttons.unshift(
      <HeaderButton key="delete_button" onClick={onDelete}>
        Delete Entry
      </HeaderButton>
    );
  }

  return (
    <AssetHeader
      action={isCreate ? "Creating entry" : "Editing entry"}
      name={name}
      buttons={buttons}
    />
  );
};

export default AssetEditHeader;
