import * as React from "react";

import { Grid } from "@material-ui/core";
import { AssetViewSectionProps } from "../AssetView";
import { StaticAnswer } from "./StaticAnswer";
import { MultiSelectView } from "./MultiSelectView";
import { ReadOnlyLabelValue } from "./ReadOnlyLabelValue";

import { DIGITAL_STORAGE_SECURITY_LABELS, PAPER_STORAGE_SECURITY_LABELS } from "../../data";

// An explicit "none of the above" answer to augment (DIGITAL|PAPER)_STORAGE_SECURITY_LABELS
const noneOfTheAboveLabel = { value: "none", label: "None of the above" };

/**
 * Display storage and security section of asset view
 */
export const StorageAndSecurityView = ({ asset }: AssetViewSectionProps) => {
  const isDigital = asset.storageFormat
    ? asset.storageFormat.indexOf("digital") !== -1
      ? "yes"
      : "no"
    : "";
  const isPhysical = asset.storageFormat
    ? asset.storageFormat.indexOf("paper") !== -1
      ? "yes"
      : "no"
    : "";
  return (
    <Grid container spacing={5} alignItems="center">
      <ReadOnlyLabelValue label="Where is the asset stored?" value={asset.storageLocation} />
      <StaticAnswer label="Is the asset stored in a digital format?" value={isDigital} />
      {isDigital === "yes" && (
        <MultiSelectView
          label="What security measures are followed to be keep the digital asset safe?"
          options={[...DIGITAL_STORAGE_SECURITY_LABELS, noneOfTheAboveLabel]}
          values={asset.digitalStorageSecurity}
        />
      )}
      <StaticAnswer label="Is the asset stored in a physical format?" value={isPhysical} />
      {isPhysical === "yes" && (
        <MultiSelectView
          label="What security measures are followed to be keep the physical asset safe?"
          options={[...PAPER_STORAGE_SECURITY_LABELS, noneOfTheAboveLabel]}
          values={asset.paperStorageSecurity}
        />
      )}
    </Grid>
  );
};

export default StorageAndSecurityView;
