/**
 * Render different application pages based on the current path.
 */
import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import {
  AssetListPage,
  AssetFormPage,
  AssetViewPage,
  StaticPage,
  RedirectToMyDeptAssets,
} from "../pages";

export const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact={true} path="/assets/:instId" component={AssetListPage} />
      <Route exact={true} path="/assets" component={AssetListPage} />

      <Route exact={true} path="/asset/" component={AssetFormPage} />
      <Route exact={true} path="/asset/:assetId/edit" component={AssetFormPage} />
      <Route exact={true} path="/asset/:assetId" component={AssetViewPage} />

      <Route exact={true} path="/help/" component={() => <StaticPage page="help" />} />
      <Route exact={true} path="/feedback/" component={() => <StaticPage page="feedback" />} />

      <Route exact={true} path="/" component={RedirectToMyDeptAssets} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
