import * as React from "react";
import { useParams } from "react-router-dom";

import { Box } from "@material-ui/core";

import { GenericPage } from ".";
import AssetViewHeader from "../components/AssetViewHeader";
import AssetView from "../components/AssetView";

import { useAssets, useLookupInstName } from "../api";

export const AssetViewPage: React.FunctionComponent = () => {
  const { assetId } = useParams();
  const { getAsset } = useAssets();
  const nameLookup = useLookupInstName();
  const scrolledToTop = React.useRef(false);

  React.useEffect(() => {
    if (!scrolledToTop.current) {
      scrolledToTop.current = true;
      window.scrollTo(0, 0);
    }
  }, [scrolledToTop]);

  if (assetId === undefined) {
    // Shouldn't happen but to keep typescript
    return null;
  }

  const asset = getAsset(assetId);
  if (asset && asset.department && !asset.instName) {
    asset.instName = nameLookup(asset.department);
  }

  const appBar = <AssetViewHeader name={asset && asset.name ? asset.name : "..."} />;

  return (
    <GenericPage withSidebar={false} appBarChildren={appBar}>
      <Box px={9} py={4}>
        <AssetView asset={asset} isLoading={asset === null} />
      </Box>
    </GenericPage>
  );
};

export default AssetViewPage;
