import * as React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

export interface DeleteConfirmationProps {
  open: boolean;
  assetName: string | null;
  onClose: () => void;
  onDelete: () => void;
}

/**
 * Dialog box requesting confirmation of deletion of the named asset
 */
export const DeleteConfirmation = ({
  open,
  assetName,
  onClose,
  onDelete,
}: DeleteConfirmationProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Delete Asset Permanently?</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Once deleted, the asset <strong>"{assetName}"</strong> cannot be recovered.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button onClick={onDelete} color="primary">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteConfirmation;
