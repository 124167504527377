import * as React from "react";

import { Grid } from "@material-ui/core";
import { AssetEditSectionProps } from "../../containers/AssetEdit";
import { DraftTextInput } from "../../containers/DraftTextInput";
import { DraftSelectInput } from "../../containers/DraftSelectInput";
import { DraftSwitchInput } from "../../containers/DraftSwitchInput";
import { DraftPersonLookup } from "../../containers/DraftPersonLookup";

import { PURPOSE_LABELS } from "../../data";

/**
 * Allow editing of general information section of asset
 */
export const GeneralInformationEdit = ({ asset, institutions }: AssetEditSectionProps) => {
  return (
    <Grid container spacing={5}>
      <DraftTextInput
        label="Asset name"
        helperText={
          "Give the asset a unique name so you can easily identify it, for example, " +
          "'Visiting academics database'. If your institution has sub-divisions, or " +
          "is part of the Academic Division you should reference that too, " +
          "eg ‘(Student Registry) HR Records’."
        }
        name="name"
        value={asset.name}
        halfWidth={true}
        halfSpacer={true}
      />

      <DraftSelectInput
        label="Asset department"
        options={institutions || []}
        name="department"
        value={asset.department}
        halfWidth={true}
        halfSpacer={true}
      />

      <DraftSelectInput
        label="Asset purpose"
        options={PURPOSE_LABELS}
        name="purpose"
        value={asset.purpose}
        halfWidth={true}
      />

      {
        // If the purpose is "research", display it
        asset.purpose === "research" ? (
          <DraftPersonLookup
            halfWidth={true}
            label="Who is the Principal Investigator?"
            name="owner"
            value={asset.owner}
            helperText={"You can search by either name or CRSid"}
          />
        ) : null
      }
      {
        // If the purpose is "other", display it
        asset.purpose === "other" ? (
          <DraftTextInput
            halfWidth={true}
            label="Asset purpose"
            name="purposeOther"
            value={asset.purposeOther}
          />
        ) : null
      }

      <DraftSwitchInput
        name="private"
        label="Make this entry private to your institution"
        value={asset.private}
      />
    </Grid>
  );
};

export default GeneralInformationEdit;
