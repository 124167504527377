/**
 * Support for lookup API
 */

import * as React from "react";

import {
  APIError,
  AssetsResponse,
  AssetsQuery,
  Asset,
  useAuthenticatedAPIFetch,
  appendQuery,
} from ".";
import { ASSETS_URL, DUMP_URL } from "./constants";

import { useSnackbars } from "../containers/Snackbars";

/**
 * Institutions component of LookupState
 */
export type AssetsState = { [assetId: string]: Asset };

/**
 * Assets state available through AssetsContext.
 */
export interface AssetsContextState {
  // whether or not an api page fetch is in progress
  isLoading: boolean;
  // the query applied when fetching a page of assets
  query: AssetsQuery;
  // Assets retrieved
  assets: AssetsState;
  // Ordered as Asset Ids
  assetIds: string[];
  // URL for retrieving next page of assets
  next: string | null;
  // Last API error
  error?: APIError;

  // Functionality for updating retrieved assets
  setOrdering: (ordering: string | null) => void;
  setDepartment: (instid: string | null) => void;
  nextPage: () => void;

  // whether or not an api single asset fetch is in progress
  isLoadingAsset: boolean;
  // which asset last asked to retrieve
  assetId: string;
  // Functionality for retrieving a single asset
  getAsset: (id: string) => Asset | null;
  // Functionality for creating, updating and deleting an asset
  createAsset: (draft: Asset) => Promise<void>;
  updateAsset: (draft: Asset) => Promise<void>;
  deleteAsset: (asset: Asset) => Promise<void>;
  // Functionality for downloading the list of assets as a CSV
  dumpAssets: (instid: string | null) => Promise<void>;
}

// Initial context state - no assets retrieved
const INITIAL_CONTEXT_STATE = {
  isLoading: false,
  query: {},
  assets: {},
  assetIds: [],
  next: null,
  setOrdering: () => {},
  setDepartment: () => {},
  nextPage: () => {},
  isLoadingAsset: false,
  assetId: "",
  getAsset: () => null,
  createAsset: () => {
    return Promise.resolve();
  },
  updateAsset: () => {
    return Promise.resolve();
  },
  deleteAsset: () => {
    return Promise.resolve();
  },
  dumpAssets: () => {
    return Promise.resolve();
  },
};

/**
 * Assets context. Holds assets context state for children of its provider.
 */
export const AssetsContext = React.createContext<AssetsContextState>(INITIAL_CONTEXT_STATE);

/**
 * Convenience hook to access the current assets context state.
 */
export const useAssets = () => React.useContext(AssetsContext);

/**
 * Assets provider. Wrap any components which need Assets state access in this
 * provider. Passes the current state down as a value.
 */
export const AssetsProvider: React.FunctionComponent = ({ children }) => (
  <AssetsContext.Provider value={useAssetsProviderValue()}>{children}</AssetsContext.Provider>
);

/**
 * Internal hook which manages assets context state for the AssetsProvider.
 */
const useAssetsProviderValue = () => {
  // Create assets context state
  const [state, stateDispatch] = React.useReducer(contextReducer, INITIAL_CONTEXT_STATE);

  // need authenticated api fetch
  const authAPI = useAuthenticatedAPIFetch();

  // snackbar notifications on errors
  const { addSnackbar } = useSnackbars();
  React.useEffect(() => {
    if (state.error && state.error.error && state.error.error.message) {
      addSnackbar({ type: "error", message: `API Error: ${state.error.error.message}` });
    }
  }, [state.error, addSnackbar]);

  // A ref to store the last issued request for page of assets
  const lastPromise = React.useRef<Promise<AssetsResponse>>();
  // same for single asset request
  const lastPromiseAsset = React.useRef<Promise<Asset>>();

  // Setting isLoading triggers load
  const { isLoading, query, next } = state;
  React.useEffect(() => {
    if (isLoading) {
      // Api call wrapper
      const assetsApiCall = (next: string | null, query: AssetsQuery): Promise<AssetsResponse> => {
        if (!authAPI) {
          throw new Error("No authentication");
        }
        return authAPI(appendQuery(next || ASSETS_URL, query));
      };
      const currentPromise = assetsApiCall(next, query);
      // remember the last promise (api request) so that it can be checked for
      // and only the latest one actioned when multiple are triggered at once
      lastPromise.current = currentPromise;
      currentPromise
        .then((response) => {
          if (currentPromise === lastPromise.current) {
            stateDispatch({ type: "ADD_ASSETS", payload: response });
          }
        })
        .catch((e) => {
          if (currentPromise === lastPromise.current) {
            stateDispatch({ type: "SET_ERROR", payload: e });
          }
        });
    }
  }, [isLoading, query, next, authAPI]);

  const setOrdering = React.useCallback(
    (ordering: string | null) => {
      stateDispatch({ type: "SET_ORDER", payload: ordering });
    },
    [stateDispatch]
  );

  const setDepartment = React.useCallback(
    (instid: string | null) => {
      stateDispatch({ type: "SET_DEPT", payload: instid });
    },
    [stateDispatch]
  );

  const nextPage = React.useCallback(() => {
    stateDispatch({ type: "NEXT_PAGE" });
  }, [stateDispatch]);

  // Setting isLoadingAsset triggers load
  const { isLoadingAsset, assetId } = state;
  React.useEffect(() => {
    if (isLoadingAsset) {
      // Api call wrapper
      const assetApiCall = (id: string): Promise<Asset> => {
        if (!authAPI) {
          throw new Error("No authentication");
        }
        return authAPI(`${ASSETS_URL}${id}/`);
      };
      const currentPromise = assetApiCall(assetId);
      // remember the last promise (api request) so that it can be checked for
      // and only the latest one actioned when multiple are triggered at once
      lastPromiseAsset.current = currentPromise;
      currentPromise
        .then((response) => {
          if (currentPromise === lastPromiseAsset.current) {
            stateDispatch({ type: "ADD_ASSET", payload: response });
          }
        })
        .catch((e) => {
          if (currentPromise === lastPromiseAsset.current) {
            stateDispatch({ type: "SET_ERROR", payload: e });
          }
        });
    }
  }, [isLoadingAsset, assetId, authAPI]);

  const getAsset = React.useCallback(
    (id: string): Asset | null => {
      // Already have it so return it
      if (id in state.assets) {
        return state.assets[id];
      }
      stateDispatch({ type: "GET_ASSET", payload: id });
      return null;
    },
    [state.assets, stateDispatch]
  );

  const createAsset = React.useCallback(
    async (draft: Asset) => {
      if (!authAPI) {
        throw new Error("No authentication");
      }
      try {
        await authAPI(ASSETS_URL, {
          method: "POST",
          body: JSON.stringify(draft),
        });
        // reset assets in state to force reload
        stateDispatch({ type: "RESET_ASSETS" });
        addSnackbar({ type: "success", message: `Asset ${draft.name} created` });
        return Promise.resolve();
      } catch (e) {
        stateDispatch({ type: "SET_ERROR", payload: e });
        return Promise.reject(e);
      }
    },
    [authAPI, stateDispatch, addSnackbar]
  );

  const updateAsset = React.useCallback(
    async (draft: Asset) => {
      if (!authAPI) {
        throw new Error("No authentication");
      }
      try {
        const response = await authAPI(draft.url, {
          method: "PUT",
          body: JSON.stringify(draft),
        });
        // update asset in state
        stateDispatch({ type: "ADD_ASSET", payload: response });
        addSnackbar({ type: "success", message: `Asset ${draft.name} updated` });
        return Promise.resolve();
      } catch (e) {
        stateDispatch({ type: "SET_ERROR", payload: e });
        return Promise.reject(e);
      }
    },
    [authAPI, stateDispatch, addSnackbar]
  );

  const deleteAsset = React.useCallback(
    async (asset: Asset) => {
      if (!authAPI) {
        throw new Error("No authentication");
      }
      try {
        await authAPI(asset.url, { method: "DELETE" });
        // update asset in state
        stateDispatch({ type: "DELETE_ASSET", payload: asset.id });
        addSnackbar({ type: "success", message: `Deleted ${asset.name}` });
        return Promise.resolve();
      } catch (e) {
        stateDispatch({ type: "SET_ERROR", payload: e });
        return Promise.reject(e);
      }
    },
    [authAPI, stateDispatch, addSnackbar]
  );

  const dumpAssets = React.useCallback(
    async (instid: string | null) => {
      if (!authAPI) {
        throw new Error("No authentication");
      }
      try {
        const query: AssetsQuery = {};
        if (instid !== null) {
          query.department = instid;
        }
        addSnackbar({ message: "Downloading assets..." });
        // Get csv dump
        const response = await authAPI(appendQuery(DUMP_URL, query), {}, "text/csv");
        // Create a data href link on page and click it
        const link = document.createElement("a");
        link.setAttribute("href", `data:text/csv;charset=utf-8,${encodeURI(response)}`);
        link.setAttribute("download", `assets-${instid ? instid : "all"}.csv`);
        document.body.appendChild(link);
        link.click();

        return Promise.resolve();
      } catch (e) {
        stateDispatch({ type: "SET_ERROR", payload: e });
        return Promise.reject(e);
      }
    },
    [authAPI, addSnackbar]
  );

  return {
    ...state,
    setOrdering,
    setDepartment,
    nextPage,
    getAsset,
    createAsset,
    updateAsset,
    deleteAsset,
    dumpAssets,
  };
};

/* Actions for context reducer */
interface AssetsAddAction {
  type: "ADD_ASSETS";
  payload: AssetsResponse;
}
interface AssetsErrorAction {
  type: "SET_ERROR";
  payload: APIError;
}
interface AssetsOrderAction {
  type: "SET_ORDER";
  payload: string | null;
}
interface AssetsDeptAction {
  type: "SET_DEPT";
  payload: string | null;
}
interface AssetsNextPageAction {
  type: "NEXT_PAGE";
}
interface AssetAddAction {
  type: "ADD_ASSET";
  payload: Asset;
}
interface AssetGetAction {
  type: "GET_ASSET";
  payload: string;
}
interface AssetsResetAction {
  type: "RESET_ASSETS";
}
interface AssetDeleteAction {
  type: "DELETE_ASSET";
  payload: string;
}

type AssetsActions =
  | AssetsAddAction
  | AssetsErrorAction
  | AssetsOrderAction
  | AssetsDeptAction
  | AssetsNextPageAction
  | AssetAddAction
  | AssetGetAction
  | AssetsResetAction
  | AssetDeleteAction;

/**
 * Overall Assets Context Reducer
 **/
const contextReducer = (state: AssetsContextState, action: AssetsActions): AssetsContextState => {
  const newQuery = { ...state.query };
  switch (action.type) {
    case "ADD_ASSETS":
      // Strip duplicates (from simulateous loading) and drop out if nothing left
      const newAssets = action.payload.results.filter((a: Asset) => !(a.id in state.assets));
      if (!newAssets.length) {
        return { ...state, isLoading: false };
      }
      const newIds = newAssets.map((a) => a.id);
      const assetsByIds = Object.assign({}, ...newAssets.map((a) => ({ [a.id]: a })));
      return {
        ...state,
        next: action.payload.next === undefined ? null : action.payload.next,
        assets: { ...state.assets, ...assetsByIds },
        assetIds: [...state.assetIds, ...newIds],
        isLoading: false,
      };
    case "SET_ERROR":
      return { ...state, error: action.payload };
    case "SET_ORDER":
      if (action.payload === null) {
        // Clear ordering from query
        delete newQuery.ordering;
      } else {
        newQuery.ordering = action.payload;
      }
      // Trigger load if query changed
      if (queryChanged(state.query, newQuery)) {
        return {
          ...state,
          query: newQuery,
          assets: {},
          assetIds: [],
          next: null,
          isLoading: true,
        };
      }
      return state;
    case "SET_DEPT":
      if (action.payload === null) {
        // Clear department from query
        delete newQuery.department;
      } else {
        newQuery.department = action.payload;
      }
      // Trigger load if query changed. Also force load if no assets currently loaded
      // as is the case when refreshing the All Institutions page
      if (!state.assetIds.length || queryChanged(state.query, newQuery)) {
        return {
          ...state,
          query: newQuery,
          assets: {},
          assetIds: [],
          next: null,
          isLoading: true,
        };
      }
      return state;
    case "NEXT_PAGE":
      if (state.isLoading || state.next === null) {
        // No more pages to get or we're still loading one
        return state;
      }
      return { ...state, isLoading: true };
    case "ADD_ASSET":
      // add the retrieved asset just to the assets map
      return {
        ...state,
        assets: { ...state.assets, [action.payload.id]: action.payload },
        isLoadingAsset: false,
      };
    case "GET_ASSET":
      // asset already requested so don't trigger load
      if (state.assetId === action.payload) {
        return state;
      }
      // setting isLoadingAsset will trigger load for assetId
      return {
        ...state,
        assetId: action.payload,
        isLoadingAsset: true,
      };
    case "RESET_ASSETS":
      return {
        ...state,
        assetIds: [],
      };
    case "DELETE_ASSET":
      // remove asset from both assetId list and assets map
      const { [action.payload]: _, ...otherAssets } = state.assets;
      return {
        ...state,
        assetIds: state.assetIds.filter((a) => a !== action.payload),
        assets: otherAssets,
      };
    default:
      throw new Error(`Unknown action: ${action}`);
  }
};

const queryChanged = (q1: AssetsQuery, q2: AssetsQuery): boolean => {
  return q1.department !== q2.department || q1.ordering !== q2.ordering;
};
