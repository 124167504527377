import * as React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography, Box, Button, Toolbar, Paper, Link, Hidden } from "@material-ui/core";

import LogoImage from "../images/logo.svg";

import { Profile } from "../api";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      toolbar: {
        padding: "30px 40px",
      },
      logo: {
        width: "180px",
        marginRight: "20px",
      },
      appName: {
        flex: 1,
      },
      body: {
        backgroundColor: theme.palette.background.default,
        maxWidth: "850px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px",
      },
      title: {
        paddingTop: "60px",
        color: theme.palette.error.main,
      },
      description: {
        paddingTop: "30px",
        color: theme.palette.grey[700],
      },
    }),
  { name: "SignoutBanner" }
);

export interface UnauthorisedBannerProps {
  /** Callback indicating user clicked sign out button */
  onSignOut?: () => void;
  /** Signed in user's profile */
  profile?: Profile | undefined;
}

/**
 * Page which encourages an unauthorised user to sign out of the site.
 */
export const UnauthorisedBanner: React.FunctionComponent<UnauthorisedBannerProps> = ({
  onSignOut = () => null,
  profile = null,
}) => {
  const classes = useStyles();

  const signOutButton = (
    <Button color="primary" onClick={onSignOut} variant="contained">
      Sign out
    </Button>
  );

  return (
    <Box>
      <Toolbar className={classes.toolbar}>
        <img src={LogoImage} className={classes.logo} alt="Cambridge University Logo" />
        <Typography variant="h6" className={classes.appName}>
          Information Asset Register
        </Typography>
        <Hidden smDown={true}>{signOutButton}</Hidden>
      </Toolbar>
      <Paper elevation={0} classes={{ root: classes.body }}>
        <Typography variant="h4" className={classes.title}>
          You don’t have permission to use this service.
        </Typography>
        <Typography className={classes.description}>
          You are signed in as{" "}
          <strong>{profile && `${profile.displayName} (${profile.email})`}</strong>.
        </Typography>
        <Typography className={classes.description}>
          <Typography component="span">To request access please </Typography>
          <Link
            href="https://www.lookup.cam.ac.uk/group/uis-iar-admins/members?personOrder=inst_names&personMax=1000000"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact an admin
          </Link>
          <Typography component="span"> from your institution.</Typography>
        </Typography>
        <Typography className={classes.description}>{signOutButton}</Typography>
      </Paper>
    </Box>
  );
};

export default UnauthorisedBanner;
