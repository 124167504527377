import * as React from "react";
import { CircularProgress } from "@material-ui/core";

export interface InstitutionNameProps {
  instName: string | null;
}

/*
 * Component displays an institution name if available or loading indicator if not
 */
export const InstitutionName = ({ instName }: InstitutionNameProps) => (
  <>{instName === null ? <CircularProgress size={10} /> : instName}</>
);

export default InstitutionName;
