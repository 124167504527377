import * as React from "react";

import { Link, useLocation } from "react-router-dom";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";

import { makeStyles, createStyles, Theme, StyledComponentProps } from "@material-ui/core/styles";

import InstitutionName from "../components/InstitutionName";

import { useAuthenticationState, useLookupInstName } from "../api";

import LogoImage from "../images/logo.svg";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
      },

      mainContent: {
        flexGrow: 1,
      },

      logoPanel: {
        ...theme.mixins.toolbar,
        paddingLeft: theme.spacing(2),
      },

      logoToolbar: {
        flexDirection: "column",
        alignItems: "flex-start",
      },

      logo: {
        width: "145px",
        height: "40px",
        paddingTop: "10px",
      },

      tagLine: { fontSize: 12 },

      assetHeading: {
        ...theme.typography.overline,
        padding: "0px 16px",
        opacity: 0.7,
      },

      nested: {
        paddingLeft: theme.spacing(5),
      },

      activeTab: {
        backgroundColor: theme.palette.action.selected,
      },
      inactiveTab: {},
    }),
  { name: "Sidebar" }
);

export type SidebarClassKey = keyof ReturnType<typeof useStyles>;

export interface SidebarProps extends StyledComponentProps<SidebarClassKey> {}

/**
 * Content of the sidebar navigation drawer.
 */
export const Sidebar: React.FunctionComponent<SidebarProps> = () => {
  const { profile, signOut } = useAuthenticationState();
  const location = useLocation();
  const classes = useStyles();
  const nameLookup = useLookupInstName();

  return (
    <div className={classes.root}>
      <div className={classes.logoPanel}>
        <Toolbar className={classes.logoToolbar} disableGutters={true}>
          <img src={LogoImage} className={classes.logo} alt="Cambridge University Logo" />
          <div className={classes.tagLine}>Information Asset Register</div>
        </Toolbar>
      </div>
      <Divider />

      <div className={classes.mainContent}>
        <List component="nav">
          <ListItem className={classes.assetHeading}>Institution</ListItem>
          {profile &&
            profile.institutions &&
            profile.institutions.map((instid) => {
              const url = `/assets/${instid}`;
              return (
                <ListItem
                  key={instid}
                  to={url}
                  button={true}
                  component={Link}
                  className={classes.nested}
                  selected={location.pathname === url}
                >
                  <ListItemText>
                    <InstitutionName instName={nameLookup(instid)} />
                  </ListItemText>
                </ListItem>
              );
            })}
          <ListItem
            to="/assets/"
            button={true}
            component={Link}
            className={classes.nested}
            selected={location.pathname === "/assets/"}
          >
            <ListItemText primary="All institutions" />
          </ListItem>
          <ListItem
            to="/help/"
            button={true}
            component={Link}
            selected={location.pathname === "/help/"}
          >
            <ListItemText primary="Help" />
          </ListItem>
          <ListItem
            to="/feedback/"
            button={true}
            component={Link}
            selected={location.pathname === "/feedback/"}
          >
            <ListItemText primary="Feedback" />
          </ListItem>
          <ListItem to="#" button={true} component={Link} onClick={signOut}>
            <ListItemText primary="Sign out" />
          </ListItem>
        </List>
      </div>
    </div>
  );
};

export default Sidebar;
