import * as React from "react";

import { useHistory } from "react-router-dom";

import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export const NavigateBackButton = () => {
  const history = useHistory();
  return (
    <IconButton color="inherit" aria-label="Go back" onClick={history.goBack}>
      <ArrowBackIcon />
    </IconButton>
  );
};

export default NavigateBackButton;
