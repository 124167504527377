import * as React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableBody, TableRow, TableCell } from "@material-ui/core";

import { AssetListItem } from "./AssetListItem";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      tableBody: {
        backgroundColor: theme.palette.background.paper,
      },
    }),
  { name: "AssetTableBody" }
);

export interface AssetSummary {
  id: string;
  name: string | null;
  isComplete: boolean;
  department: string | null;
  isPrivate: boolean;
  updatedAt: string;
  allowedMethods: string[] | null;
  instName: string | null;
}

export interface AssetTableBodyProps {
  summaries: AssetSummary[];
  isLoading: boolean;
  onDelete: (id: string) => void;
}

/**
 * Body of asset summaries list containing each asset item or a notification when
 * no assets are available
 */
export const AssetTableBody = ({ summaries, isLoading, onDelete }: AssetTableBodyProps) => {
  const classes = useStyles();

  return (
    <TableBody className={classes.tableBody}>
      {
        // Display a "no assets" row if there is no loading happening and there are no assets.
        summaries.length === 0 && !isLoading ? <ZeroAssetsRow /> : null
      }
      {summaries.map((asset) => (
        <AssetListItem key={asset.id} onDelete={onDelete} asset={asset} />
      ))}
    </TableBody>
  );
};

export const ZeroAssetsRow = () => (
  <TableRow>
    <TableCell colSpan={6} align="center">
      There are no asset entries to display for this institution
    </TableCell>
  </TableRow>
);

export default AssetTableBody;
