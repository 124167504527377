import * as React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography, ButtonGroup, Button, ButtonProps } from "@material-ui/core";

import { NavigateBackButton } from "./NavigateBackButton";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      title: {
        flex: 1,
        fontWeight: theme.typography.h5.fontWeight,
      },
      buttonGroup: {
        paddingRight: theme.spacing(3),
      },
      button: {
        marginLeft: theme.spacing(2),
        width: theme.spacing(27),
      },
    }),
  { name: "AssetHeader" }
);

export interface AssetHeaderProps {
  action: string;
  name: string;
  buttons: React.ReactNode;
}

export const AssetHeader = ({ action, name, buttons }: AssetHeaderProps) => {
  const classes = useStyles();

  return (
    <>
      <NavigateBackButton />
      <Typography variant="h6" color="inherit" className={classes.title}>
        {`${action}: `}
        <strong>{name}</strong>
      </Typography>
      <ButtonGroup color="primary" className={classes.buttonGroup}>
        {buttons}
      </ButtonGroup>
    </>
  );
};

export default AssetHeader;

// A wrapper for Button which defaults to the right props for the header.
export const HeaderButton: React.FunctionComponent<ButtonProps> = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <Button color="primary" {...rest} variant="contained" className={classes.button}>
      {children}
    </Button>
  );
};
