// Polyfill missing features for older IEs and the browsers listed in the browserslist field in
// package.json.
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

import { Routes } from "./containers";
import { Snackbars } from "./containers/Snackbars";

import {
  AuthenticationProvider,
  LoadGoogleOAuthProvider,
  LookupProvider,
  AssetsProvider,
} from "./api";

// Render application content
ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Snackbars>
      <LoadGoogleOAuthProvider>
        <AuthenticationProvider>
          <LookupProvider>
            <AssetsProvider>
              <Routes />
            </AssetsProvider>
          </LookupProvider>
        </AuthenticationProvider>
      </LoadGoogleOAuthProvider>
    </Snackbars>
  </MuiThemeProvider>,
  document.getElementById("root")
);
