import * as React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography, Box, Button, Toolbar, Paper, Hidden } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import LogoImage from "../images/logo.svg";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      toolbar: {
        padding: "30px 40px",
      },
      logo: {
        width: "180px",
        marginRight: "20px",
      },
      appName: {
        flex: 1,
      },
      body: {
        backgroundColor: theme.palette.background.default,
        maxWidth: "850px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px",
      },
      title: {
        paddingTop: "60px",
      },
      description: {
        maxWidth: "600px",
        paddingTop: "30px",
        lineHeight: 1.2,
        color: theme.palette.grey[700],
      },
    }),
  { name: "SigninBanner" }
);

export interface SignInBannerProps {
  /** Callback indicating user clicked sign in button */
  onSignIn?: () => void;

  /** Is user engaged in a sign in process? */
  isSigningIn?: boolean;

  showError?: boolean;
}

/**
 * Page which encourages users to sign in to the site.
 */
export const SignInBanner: React.FunctionComponent<SignInBannerProps> = ({
  onSignIn = () => null,
  isSigningIn = false,
  showError = false,
}) => {
  const classes = useStyles();

  const signInButton = (
    <Button color="primary" onClick={onSignIn} disabled={isSigningIn} variant="contained">
      Sign In
    </Button>
  );

  return (
    <Box>
      <Toolbar className={classes.toolbar}>
        <img src={LogoImage} className={classes.logo} alt="Cambridge University Logo" />
        <Typography variant="h6" className={classes.appName}>
          Information Asset Register
        </Typography>
        <Hidden smDown={true}>{signInButton}</Hidden>
      </Toolbar>
      <Paper elevation={0} classes={{ root: classes.body }}>
        {showError && (
          <Alert severity="warning">
            Something went wrong, the sign-in popup may have been blocked by your browser or you
            may have closed it before the sign-in was complete. Please try again, and if the issue
            persists contact the UIS helpdesk at{" "}
            <a href="mailto: servicedesk@uis.cam.ac.uk">servicedesk@uis.cam.ac.uk</a>.
          </Alert>
        )}
        <Typography variant="h4" className={classes.title}>
          Information asset register:
          <br /> record details of information held by your institution
        </Typography>
        <Typography className={classes.description}>
          You must record details of certain information assets (databases, lists or other
          collections of data) held by your institution. These details should be checked and
          updated as necessary and at least annually. You can download a report of all of your
          institution’s entries to help check them.
        </Typography>
        <Typography className={classes.description}>
          This will help the University meet its duties under data protection legislation.
        </Typography>
        <Typography className={classes.description}>
          Use the information asset register (IAR) to add, edit and view the details of these
          information assets. You cannot upload the data itself.
        </Typography>
        <Typography className={classes.description}>
          {signInButton} <small>This opens in a pop-up</small>
        </Typography>
      </Paper>
    </Box>
  );
};

export default SignInBanner;
