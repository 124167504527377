/**
 * Custom University of Cambridge material UI theme
 */

// Since the ordering of properties has some meaning to users editing this file even if they are of
// no interest to the code, we disable the alphabetical sort check.
//
// tslint:disable:object-literal-sort-keys

import { createMuiTheme } from "@material-ui/core/styles";

export const drawerWidth = 35 * 8;

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#91b9a4",
      main: "#00b1c1",
      dark: "#106470",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fff",
      main: "#fff",
      dark: "#ccc",
      contrastText: "#00b1c1",
    },
    background: {
      paper: "#fff",
      default: "#f3f3f3",
    },
  },
});

export default theme;
