import * as React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Grid,
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      group: {
        paddingTop: theme.spacing(1),
      },
      unanswered: {
        padding: theme.spacing(0),
        fontStyle: "italic",
      },
      legendLabel: {
        color: "inherit",
      },
      optionRoot: {
        marginRight: theme.spacing(6),
        paddingRight: theme.spacing(3),
        borderBottom: ["1px", "solid", theme.palette.divider].join(" "),
      },
      optionLabel: {
        fontSize: theme.typography.body2.fontSize,
        color: ["inherit", "!important"].join(" "),
      },
      optionCheckbox: {
        color: [theme.palette.primary.main, "!important"].join(" "),
      },
    }),
  { name: "MultiSelectView" }
);

export type selectOptions = { value: string; label: string }[];

export interface MultiSelectViewProps {
  label: string;
  options: selectOptions;
  values: string[] | null;
}

/**
 * Displays a label and list of (multiple) selected options as disabled form controls
 */
export const MultiSelectView = ({ label, values, options }: MultiSelectViewProps) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend" classes={{ root: classes.legendLabel }}>
            {label}
          </FormLabel>
          <FormGroup classes={{ root: classes.group }}>
            {options
              .filter(({ value }) => values !== null && values.indexOf(value) !== -1)
              .map(({ value, label }) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  label={label}
                  checked={true}
                  disabled
                  control={
                    <Checkbox
                      checkedIcon={<CheckIcon />}
                      classes={{ disabled: classes.optionCheckbox }}
                    />
                  }
                  classes={{ root: classes.optionRoot, label: classes.optionLabel }}
                />
              ))}
            {values === null || !values.length ? (
              <div className={classes.unanswered}>Unanswered</div>
            ) : null}
          </FormGroup>
        </FormControl>
      </Grid>
    </>
  );
};

export default MultiSelectView;
