import * as React from "react";

import { Grid } from "@material-ui/core";
import { AssetViewSectionProps } from "../AssetView";
import { MultiSelectView } from "./MultiSelectView";
import { ReadOnlyLabelValue } from "./ReadOnlyLabelValue";

import { RISK_TYPE_LABELS } from "../../data";

// An explicit "none of the above" answer to augment RISK_TYPE_LABELS
const noneOfTheAboveLabel = { value: "none", label: "None of the above" };

/**
 * Display risks section of asset view
 */
export const RisksView = ({ asset }: AssetViewSectionProps) => (
  <Grid container spacing={5} alignItems="center">
    <MultiSelectView
      label="What are the risks if the information in this asset were lost or compromised?"
      options={[...RISK_TYPE_LABELS, noneOfTheAboveLabel]}
      values={asset.riskType}
    />
    <ReadOnlyLabelValue
      label="Additional information"
      value={asset.riskTypeAdditional || "None"}
    />
  </Grid>
);

export default RisksView;
