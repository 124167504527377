import * as React from "react";
import { Grid, FormControlLabel, Switch, Hidden } from "@material-ui/core";

import { useDraft } from "../pages/AssetFormPage";

export interface DraftSwitchInputProps {
  name: string;
  label: string;
  value: boolean | null;
  halfWidth?: boolean;
  halfSpacer?: boolean;
  required?: boolean;
}

/**
 * A text input component for editing draft assets
 */
export const DraftSwitchInput = ({
  name,
  label,
  value,
  halfWidth = false,
  halfSpacer = false,
  required = true,
}: DraftSwitchInputProps) => {
  const { patchDraft } = useDraft();

  const handleChange = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    patchDraft({ [name]: checked });
  };

  return (
    <>
      <Grid item xs={12} md={halfWidth ? 6 : 12}>
        <FormControlLabel
          label={label}
          control={
            <Switch
              required={required}
              checked={!!value}
              onChange={handleChange}
              color="primary"
            />
          }
        />
      </Grid>
      {halfSpacer ? (
        <Hidden smDown>
          <Grid item md={6} />
        </Hidden>
      ) : null}
    </>
  );
};

export default DraftSwitchInput;
