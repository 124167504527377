/*
 * Useful constants and functions when editing or creating an asset
 */
import { Asset } from "./api";

// Default asset when creating a new one
export const DEFAULT_ASSET: Asset = {
  url: "",
  id: "",
  dataSubject: [],
  dataCategory: [],
  riskType: [],
  storageFormat: [],
  paperStorageSecurity: [],
  digitalStorageSecurity: [],
  isComplete: false,
  allowedMethods: ["POST"],
  name: "New Asset",
  department: null,
  purpose: null,
  purposeOther: null,
  owner: null,
  private: false,
  personalData: null,
  recipientsOutsideUni: null,
  recipientsOutsideEea: null,
  recipientsOutsideUniDescription: null,
  recipientsOutsideEeaDescription: null,
  retention: null,
  riskTypeAdditional: null,
  storageLocation: null,
  createdAt: "",
  updatedAt: "",
  instName: null,
};

// Helper function for determining if a particular asset field has been filled in.
const isNotEmpty = (v: string | boolean | null | undefined) => v !== undefined && v !== null;

/*
 * Completeness checks for each section
 */

export const isGeneralInformationComplete = (asset: Asset): boolean => {
  const { name, purpose, purposeOther, department, owner } = asset;
  return (
    isNotEmpty(name) &&
    isNotEmpty(purpose) &&
    isNotEmpty(department) &&
    (purpose !== "research" || isNotEmpty(owner)) &&
    (purpose !== "other" || isNotEmpty(purposeOther))
  );
};

export const isPersonalDataComplete = (asset: Asset): boolean => {
  const {
    personalData,
    dataSubject,
    dataCategory,
    recipientsOutsideUni,
    recipientsOutsideUniDescription,
    recipientsOutsideEea,
    recipientsOutsideEeaDescription,
    retention,
  } = asset;
  return (
    (isNotEmpty(personalData) && !personalData) ||
    (!!dataSubject &&
      dataSubject.length > 0 &&
      !!dataCategory &&
      dataCategory.length > 0 &&
      isNotEmpty(recipientsOutsideUni) &&
      (recipientsOutsideUni !== "yes" || isNotEmpty(recipientsOutsideUniDescription)) &&
      isNotEmpty(recipientsOutsideEea) &&
      (recipientsOutsideEea !== "yes" || isNotEmpty(recipientsOutsideEeaDescription)) &&
      isNotEmpty(retention))
  );
};

export const isRisksComplete = (asset: Asset): boolean => {
  const { riskType } = asset;
  return !!riskType && riskType.length > 0;
};

export const isStorageComplete = (asset: Asset): boolean => {
  const { storageLocation, storageFormat, paperStorageSecurity, digitalStorageSecurity } = asset;
  return (
    isNotEmpty(storageLocation) &&
    !!storageFormat &&
    storageFormat.length > 0 &&
    (storageFormat.indexOf("digital") === -1 ||
      (!!digitalStorageSecurity && digitalStorageSecurity.length > 0)) &&
    (storageFormat.indexOf("paper") === -1 ||
      (!!paperStorageSecurity && paperStorageSecurity.length > 0))
  );
};
