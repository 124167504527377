import * as React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Paper, Box, CircularProgress, Button } from "@material-ui/core";

import AssetSection from "../components/AssetSection";
import CompleteIndicator from "../components/CompleteIndicator";
import {
  GeneralInformationEdit,
  PersonalDataEdit,
  RisksEdit,
  StorageAndSecurityEdit,
} from "../components/asseteditsections";
import { Asset, useAuthenticationState, useLookupInstName } from "../api";
import {
  isGeneralInformationComplete,
  isPersonalDataComplete,
  isRisksComplete,
  isStorageComplete,
} from "../draft";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      paper: {
        minHeight: 1000, // enlarge white paper while loading
        fontSize: theme.typography.body1.fontSize,
      },
    }),
  { name: "AssetEdit" }
);

// properties of AssetEdit component
export interface AssetEditProps {
  isLoading: boolean;
  asset: Asset | null;
  onSave: () => void;
}

// list of institution values (ids) and labels (names)
export type InstitutionOptions = { value: string; label: string }[];

// properties of section components of the AssetEdit
export interface AssetEditSectionProps {
  asset: Asset;
  institutions?: InstitutionOptions;
}

/**
 * Body of asset summaries list containing each asset item or a notification when
 * no assets are available
 */
export const AssetEdit = ({ asset, isLoading, onSave }: AssetEditProps) => {
  const classes = useStyles();
  const { profile } = useAuthenticationState();
  const nameLookup = useLookupInstName();

  const ownInsts = React.useCallback((): InstitutionOptions => {
    if (profile && profile.institutions) {
      return profile.institutions.map((i) => ({
        value: i,
        label: nameLookup(i) || i,
      }));
    }
    return [];
  }, [profile, nameLookup]);

  return (
    <Paper className={classes.paper}>
      {isLoading && (
        <Box textAlign="center" pt={10}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && asset && (
        <>
          <AssetSection
            title="General information"
            indicator={<CompleteIndicator isComplete={isGeneralInformationComplete(asset)} />}
          >
            <GeneralInformationEdit asset={asset} institutions={ownInsts()} />
          </AssetSection>
          <AssetSection
            title="Personal data"
            indicator={<CompleteIndicator isComplete={isPersonalDataComplete(asset)} />}
          >
            <PersonalDataEdit asset={asset} />
          </AssetSection>
          <AssetSection
            title="Risks"
            indicator={<CompleteIndicator isComplete={isRisksComplete(asset)} />}
          >
            <RisksEdit asset={asset} />
          </AssetSection>
          <AssetSection
            title="Storage & Security"
            indicator={<CompleteIndicator isComplete={isStorageComplete(asset)} />}
          >
            <StorageAndSecurityEdit asset={asset} />
          </AssetSection>
          <Box px={9} py={3}>
            <Button color="primary" variant="contained" onClick={onSave}>
              Save entry
            </Button>
          </Box>
        </>
      )}
    </Paper>
  );
};

export default AssetEdit;
