import * as React from "react";
import { Box, CircularProgress } from "@material-ui/core";

import SignInBanner from "../components/SignInBanner";
import UnauthorisedBanner from "../components/UnauthorisedBanner";

import { AuthStatus, useAuthenticationState } from "../api";

/**
 * A component which only shows its children if the current user is signed in.
 */
export const SignInRequired: React.FunctionComponent = ({ children }) => {
  const { authStatus, signIn, signOut, profile } = useAuthenticationState();
  const isAuthorised = Boolean(profile && profile.isAuthorised);

  switch (authStatus) {
    case AuthStatus.Authenticating:
      return (
        <Box p={2} textAlign="center">
          <CircularProgress />
        </Box>
      );
    case AuthStatus.Error:
    case AuthStatus.Unauthenticated:
      return (
        <SignInBanner
          isSigningIn={false}
          onSignIn={signIn}
          showError={authStatus === AuthStatus.Error}
        />
      );
    case AuthStatus.Authenticated:
      return isAuthorised ? (
        <>{children}</>
      ) : (
        <UnauthorisedBanner onSignOut={signOut} profile={profile} />
      );
  }
};

export default SignInRequired;
