import * as React from "react";
import { Grid, TextField, Hidden } from "@material-ui/core";

import { useDraft } from "../pages/AssetFormPage";

export interface DraftTextInputProps {
  name: string;
  label: string;
  value: string | null;
  halfWidth?: boolean;
  halfSpacer?: boolean;
  required?: boolean;
  helperText?: React.ReactNode;
}

/**
 * A text input component for editing draft assets
 */
export const DraftTextInput = ({
  name,
  label,
  value,
  halfWidth = false,
  halfSpacer = false,
  required = true,
  helperText = "",
}: DraftTextInputProps) => {
  const { patchDraft } = useDraft();

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    patchDraft({ [name]: value === "" ? null : value });
  };

  return (
    <>
      <Grid item xs={12} md={halfWidth ? 6 : 12}>
        <TextField
          fullWidth={true}
          required={required}
          label={label}
          helperText={helperText}
          value={value || ""}
          onChange={handleChange}
          inputProps={{ maxLength: 240 }}
        />
      </Grid>
      {halfSpacer ? (
        <Hidden smDown>
          <Grid item md={6} />
        </Hidden>
      ) : null}
    </>
  );
};

export default DraftTextInput;
