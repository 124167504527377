import * as React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography, Box, Toolbar, Paper } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";

import LogoImage from "../images/logo.svg";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      toolbar: {
        padding: "30px 40px",
      },
      logo: {
        width: "180px",
        marginRight: "20px",
      },
      appName: {
        flex: 1,
      },
      body: {
        backgroundColor: theme.palette.background.default,
        maxWidth: "850px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px",
      },
      title: {
        paddingTop: "60px",
        color: theme.palette.error.main,
      },
      description: {
        paddingTop: "30px",
        color: theme.palette.grey[700],
      },
    }),
  { name: "EnableCookiesBanner" }
);

/**
 * Page which explains that third-party cookies are required and how to enable them.
 */
export const EnableCookiesBanner: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Box>
      <Toolbar className={classes.toolbar}>
        <img src={LogoImage} className={classes.logo} alt="Cambridge University Logo" />
        <Typography variant="h6" className={classes.appName}>
          Information Asset Register
        </Typography>
      </Toolbar>
      <Paper elevation={0} classes={{ root: classes.body }}>
        <Typography variant="h4" className={classes.title}>
          <ErrorIcon /> Third-party cookies are disabled.
        </Typography>
        <Typography className={classes.description}>
          You must enable third-party cookies to be able to access this service. This option is
          usually located in the privacy settings in your web browser.
        </Typography>
        <Typography className={classes.description}>
          Alternatively, create an exception for the <code>accounts.google.com</code> cookie.
        </Typography>
      </Paper>
    </Box>
  );
};

export default EnableCookiesBanner;
