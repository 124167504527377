import * as React from "react";

import { Redirect } from "react-router-dom";

import { GenericPage } from ".";

import { useAuthenticationState } from "../api";

export const RedirectToMyDeptAssets: React.FunctionComponent = () => {
  const { profile } = useAuthenticationState();

  return (
    <GenericPage withSidebar={false}>
      {(profile && profile.institutions && (
        <Redirect to={"/assets/" + profile.institutions[0]} />
      )) || <div>Redirecting...</div>}
    </GenericPage>
  );
};

export default RedirectToMyDeptAssets;
