import * as React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Typography, Button } from "@material-ui/core";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      title: {
        flex: 1,
        fontWeight: theme.typography.h5.fontWeight,
      },
    }),
  { name: "AssetListHeader" }
);

export interface AssetListHeaderProps {
  title: string;
}

export const AssetListHeader = ({ title }: AssetListHeaderProps) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6" color="inherit" className={classes.title}>
        {title}
      </Typography>
      <Button color="secondary" variant="contained" component={Link} to="/asset/">
        Add new entry
      </Button>
    </>
  );
};

export default AssetListHeader;
