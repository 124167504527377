import * as React from "react";
import { makeStyles, createStyles, Theme, StyledComponentProps } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      // Styles applied to root element.
      root: {
        ...theme.typography.body1,

        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),

        [theme.breakpoints.up("sm")]: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },
      },

      // Inner content of section.
      content: {
        marginLeft: "auto",
        marginRight: "auto",

        maxWidth: theme.breakpoints.values.lg,
      },
    }),
  { name: "PageSection" }
);

export type PageSectionClassKey = keyof ReturnType<typeof useStyles>;

export interface PageSectionProps extends StyledComponentProps<PageSectionClassKey> {}

/**
 * A top-level component of a page. Responsive to page width the children will be rendered in an
 * element centred horizontally in the page with an appropriate maximum width and padding.
 */
export const PageSection: React.FunctionComponent<PageSectionProps> = (props) => {
  const { children } = props;
  const classes = useStyles(props);

  return (
    <section className={classes.root}>
      <div className={classes.content}>{children}</div>
    </section>
  );
};

export default PageSection;
