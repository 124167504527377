import * as React from "react";
import { Grid, TextField, MenuItem, Hidden } from "@material-ui/core";

import { useDraft } from "../pages/AssetFormPage";

export interface DraftSelectInputProps {
  name: string;
  label: string;
  value: string | null;
  options: { value: string; label: string }[];
  halfWidth?: boolean;
  halfSpacer?: boolean;
  required?: boolean;
  helperText?: React.ReactNode;
}

/**
 * A text input component for editing draft assets
 */
export const DraftSelectInput = ({
  name,
  label,
  value,
  options,
  halfWidth = false,
  halfSpacer = false,
  required = true,
  helperText = "",
}: DraftSelectInputProps) => {
  const { patchDraft } = useDraft();

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = value.trim();
    patchDraft({ [name]: newValue === "" ? null : newValue });
  };

  return (
    <>
      <Grid item xs={12} md={halfWidth ? 6 : 12}>
        <TextField
          select={true}
          fullWidth={true}
          required={required}
          label={label}
          helperText={helperText}
          value={value || ""}
          onChange={handleChange}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {halfSpacer ? (
        <Hidden smDown>
          <Grid item md={6} />
        </Hidden>
      ) : null}
    </>
  );
};

export default DraftSelectInput;
