import * as React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      answer: {
        display: "inline-block",
        boxSizing: "content-box",
        padding: theme.spacing(1),
        minWidth: theme.spacing(12),
        border: ["1px", "solid", theme.palette.primary.main].join(" "),
        borderRadius: theme.spacing(0.5),
        color: theme.palette.primary.dark,
        backgroundColor: "rgba(0, 177, 193, 0.08)",
        textAlign: "center",
      },
    }),
  { name: "StaticAnswer" }
);

export type answerOptions = { value: string; label: string }[];

export interface StaticAnswerProps {
  label: string;
  options?: answerOptions;
  value: string | null;
}

const YES_NO_NOTSURE = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
  { value: "not_sure", label: "Not sure" },
];

const getLabel = (options: answerOptions, value: string | null) => {
  for (let o of options) {
    if (o.value === value) {
      return o.label;
    }
  }
  return "Unanswered";
};

/**
 * Displays a label and an answer (within possible options) in a styled box
 */
export const StaticAnswer = ({ label, value, options = YES_NO_NOTSURE }: StaticAnswerProps) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} md={6}>
        {label}
      </Grid>
      <Grid item xs={12} md={6}>
        <div className={classes.answer}>{getLabel(options, value)}</div>
      </Grid>
    </>
  );
};

export default StaticAnswer;
