import * as React from "react";

import { useHistory } from "react-router-dom";

import { AssetHeader, HeaderButton } from "./AssetHeader";

export interface AssetViewHeaderProps {
  name: string;
}

export const AssetViewHeader = ({ name }: AssetViewHeaderProps) => {
  const history = useHistory();
  return (
    <AssetHeader
      action="Viewing entry"
      name={name}
      buttons={
        <HeaderButton aria-label="close" onClick={history.goBack}>
          Close
        </HeaderButton>
      }
    />
  );
};

export default AssetViewHeader;
