import * as React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  FormLabel,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Hidden,
} from "@material-ui/core";

import { useDraft } from "../pages/AssetFormPage";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      radioGroup: {
        paddingTop: theme.spacing(1),
      },
      radioLabel: {
        borderBottom: ["1px", "solid", theme.palette.divider].join(" "),
        marginRight: theme.spacing(0),
      },
    }),
  { name: "DraftArrayCheckboxInput" }
);

export interface DraftRadioGroupInputProps {
  name: string;
  label: string;
  value: string | null;
  options: { value: string; label: string }[];
  halfWidth?: boolean;
  halfSpacer?: boolean;
  required?: boolean;
  helperText?: React.ReactNode;
}

/**
 * A text input component for editing draft assets
 */
export const DraftRadioGroupInput = ({
  name,
  label,
  value,
  options,
  halfWidth = false,
  halfSpacer = false,
  required = true,
  helperText = "",
}: DraftRadioGroupInputProps) => {
  const { patchDraft } = useDraft();
  const classes = useStyles();

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = value.trim();
    patchDraft({ [name]: newValue === "" ? null : newValue });
  };

  return (
    <>
      <Grid item xs={12} md={halfWidth ? 6 : 12}>
        <FormControl component="fieldset" fullWidth={true} required={required}>
          <FormLabel component="legend">{label}</FormLabel>
          <FormHelperText>{helperText}</FormHelperText>
          <RadioGroup value={value} onChange={handleChange} className={classes.radioGroup}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                label={option.label}
                value={option.value}
                control={<Radio color="primary" />}
                className={classes.radioLabel}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      {halfSpacer ? (
        <Hidden smDown>
          <Grid item md={6} />
        </Hidden>
      ) : null}
    </>
  );
};

export default DraftRadioGroupInput;
