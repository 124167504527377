import * as React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      label: {
        whiteSpace: "nowrap",
      },
      icon: {
        fontSize: "0.9rem",
        verticalAlign: "text-bottom",
      },
    }),
  { name: "TooltipText" }
);

export interface TooltipTextProps {
  title: string;
  children: React.ReactNode;
}

/**
 * Table heading with help icon and tooltip description
 */
export const TooltipText = ({ title, children }: TooltipTextProps) => {
  const classes = useStyles();

  return (
    <Tooltip title={<div style={{ width: "16em" }}>{children}</div>}>
      <div className={classes.label}>
        {title}&nbsp;
        <HelpOutlineIcon className={classes.icon} />
      </div>
    </Tooltip>
  );
};

export default TooltipText;
