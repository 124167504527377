import * as React from "react";

import MuiAppBar from "@material-ui/core/AppBar";
import { AppBarProps } from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";

import MenuIcon from "@material-ui/icons/Menu";

// We support all the AppBar properties as well as an "onMenuClick" event handler.
export interface IProps extends AppBarProps {
  /** Event handler for click on menu "hamburger" button. */
  onMenuClick?: React.MouseEventHandler;
}

/**
 * An application bar which shows the current document's title. For small screens, there is a
 * "hamburger" menu button displayed. If the component has any children, they are rendered within
 * the central Toolbar component in place of the document title.
 */
export const AppBar = ({ children, onMenuClick, ...otherProps }: IProps) => (
  <MuiAppBar {...otherProps}>
    <Toolbar>
      {onMenuClick && (
        <Hidden lgUp={true} implementation="css">
          <IconButton color="inherit" aria-label="Open drawer" onClick={onMenuClick} edge="start">
            <MenuIcon />
          </IconButton>
        </Hidden>
      )}
      {children || null}
    </Toolbar>
  </MuiAppBar>
);

export default AppBar;
