import * as React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { Paper, Box, Typography } from "@material-ui/core";

import { GenericPage } from ".";
import { pages } from "../static";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      content: {
        padding: theme.spacing(4),
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
      },
      "@global": {
        ".static-page": {
          fontSize: "130%",
          fontWeight: 300,
        },
        ".static-page section": {
          margin: "40px 0px",
        },
        ".static-page h2,h3": {
          color: theme.palette.primary.main,
          fontWeight: theme.typography.body1.fontWeight,
        },
        ".static-page a": {
          color: theme.palette.primary.main,
          fontWeight: theme.typography.h6.fontWeight,
          textDecoration: "none",
        },
        ".static-page i": {
          color: theme.palette.primary.main,
          fontSize: theme.typography.body1.fontSize,
        },
      },
    }),
  { name: "StaticPage" }
);

export interface StaticPageProps {
  page: string;
}

export const StaticPage: React.FunctionComponent<StaticPageProps> = ({ page }) => {
  const classes = useStyles();

  const { title, content } = pages[page];
  const titleHeader = <Typography variant="h6">{title}</Typography>;

  return (
    <GenericPage appBarChildren={titleHeader}>
      <Box py={8} mx={12}>
        <Paper classes={{ root: classes.content }}>{content}</Paper>
      </Box>
    </GenericPage>
  );
};

export default StaticPage;
