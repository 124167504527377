import * as React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useParams, Redirect, Prompt, useHistory } from "react-router-dom";

import { Box, Backdrop, CircularProgress } from "@material-ui/core";

import { GenericPage } from ".";
import AssetEditHeader from "../components/AssetEditHeader";
import DeleteConfirmation from "../components/DeleteConfirmation";
import AssetEdit from "../containers/AssetEdit";
import { useSnackbars } from "../containers/Snackbars";
import { useAssets, Asset, useAuthenticationState } from "../api";

import { DEFAULT_ASSET } from "../draft";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      backdrop: {
        color: theme.palette.primary.contrastText,
        zIndex: theme.zIndex.drawer + 1,
      },
    }),
  { name: "AssetFormPage" }
);

export type DraftPatch = Partial<Asset>;

interface DraftContextState {
  draft: Asset | null;
  patchDraft: (patch: DraftPatch) => void;
}

// Holds draft context state for children of its provider
export const DraftContext = React.createContext<DraftContextState>({
  draft: null,
  patchDraft: () => {},
});

// Convenience hook to access the current draft context state.
export const useDraft = () => React.useContext(DraftContext);

// Asset Creation and Editing page
export const AssetFormPage: React.FunctionComponent = () => {
  const { assetId } = useParams();
  const { getAsset, createAsset, updateAsset, deleteAsset } = useAssets();
  const { profile } = useAuthenticationState();
  const { addSnackbar } = useSnackbars();
  const classes = useStyles();

  // Scroll to top when first rendered
  const scrolledToTop = React.useRef(false);
  React.useEffect(() => {
    if (!scrolledToTop.current) {
      scrolledToTop.current = true;
      window.scrollTo(0, 0);
    }
  }, [scrolledToTop]);

  // Hold draft state
  const [draft, dispatch] = React.useReducer(draftDispatcher, null);
  // Hold state as to whether draft has been modified
  const [isModified, setIsModified] = React.useState(false);

  // Function to patch part of the draft
  const patchDraft = React.useCallback(
    (patch: DraftPatch) => {
      dispatch({ type: "PATCH_DRAFT", payload: patch });
      setIsModified(true);
    },
    [dispatch]
  );

  // Create draft when first rendered
  React.useEffect(() => {
    if (draft === null) {
      if (assetId) {
        const asset = getAsset(assetId);
        if (asset !== null) {
          // set draft to a copy of the existing asset
          dispatch({ type: "SET_DRAFT", payload: { ...asset } });
        }
      } else {
        // Create new asset and attempt to set department id
        const asset = { ...DEFAULT_ASSET };
        if (profile && profile.institutions && profile.institutions.length === 1) {
          asset.department = profile.institutions[0];
        }
        dispatch({ type: "SET_DRAFT", payload: asset });
      }
    }
  }, [draft, dispatch, assetId, getAsset, profile]);

  // Save draft handler
  const [isSaving, setIsSaving] = React.useState(false);
  const history = useHistory();
  const saveDraft = React.useCallback(() => {
    if (draft) {
      if (draft.id) {
        // Update existing asset
        if (!isModified) {
          // nothing to save, so just go back
          setIsModified(false);
          history.goBack();
        } else {
          setIsSaving(true);
          updateAsset(draft)
            .then(() => {
              setIsSaving(false);
              setIsModified(false);
              history.goBack();
            })
            .catch(() => {
              // failed to update - clear isSaving
              setIsSaving(false);
            });
        }
      } else if (isModified) {
        // Create new asset (only if modified)
        setIsSaving(true);
        createAsset(draft)
          .then(() => {
            setIsSaving(false);
            setIsModified(false);
            history.goBack();
          })
          .catch(() => {
            // failed to create - clear isSaving
            setIsSaving(false);
          });
      } else {
        // Creating new asset but nothing modified from default draft
        addSnackbar({ message: "New asset must be modified before it can be saved" });
      }
    }
  }, [draft, isModified, setIsSaving, updateAsset, createAsset, history, addSnackbar]);

  // Whether to confirm deletion (by id)
  const [idToDelete, setIdToDelete] = React.useState<string | null>(null);

  // Delete draft handler
  const performDelete = React.useCallback(() => {
    // clear confirmation
    setIdToDelete(null);
    if (draft) {
      // show backdrop
      setIsSaving(true);
      // call deletion API
      deleteAsset(draft)
        .then(() => {
          // clear backdrop and modified flag
          setIsSaving(false);
          setIsModified(false);
          // go back to list
          history.goBack();
        })
        .catch(() => {
          // failed to delete - clear backdrop
          setIsSaving(false);
        });
    }
  }, [draft, setIdToDelete, setIsSaving, setIsModified, deleteAsset, history]);

  // Editing an asset and draft loaded but we don't have permision to edit, so redirect to view
  if (assetId && draft && (!draft.allowedMethods || draft.allowedMethods.indexOf("PUT") === -1)) {
    return <Redirect push={false} to={`/asset/${assetId}`} />;
  }

  // Create appBar
  const name = draft ? draft.name || "" : "...";
  const canDelete = draft && draft.allowedMethods && draft.allowedMethods.indexOf("DELETE") !== -1;
  const appBar = (
    <AssetEditHeader
      name={name + (isModified ? " *" : "")}
      isCreate={!assetId}
      onDelete={
        canDelete
          ? () => {
              setIdToDelete(assetId || null);
            }
          : undefined
      }
      onSave={saveDraft}
    />
  );

  return (
    <DraftContext.Provider value={{ draft, patchDraft }}>
      <Prompt
        when={isModified}
        message={
          "This entry has not yet been saved. Navigating away will cause any changes you have made to be lost."
        }
      />
      <Backdrop className={classes.backdrop} open={isSaving}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GenericPage withSidebar={false} appBarChildren={appBar}>
        <Box px={9} py={4}>
          <AssetEdit asset={draft} isLoading={draft === null} onSave={saveDraft} />
        </Box>
        <DeleteConfirmation
          open={idToDelete !== null}
          assetName={draft ? draft.name : null}
          onClose={() => {
            setIdToDelete(null);
          }}
          onDelete={performDelete}
        />
      </GenericPage>
    </DraftContext.Provider>
  );
};

interface DraftSetAction {
  type: "SET_DRAFT";
  payload: Asset;
}
interface DraftPatchAction {
  type: "PATCH_DRAFT";
  payload: DraftPatch;
}
type DraftActions = DraftSetAction | DraftPatchAction;

const draftDispatcher = (state: Asset | null, action: DraftActions): Asset | null => {
  switch (action.type) {
    case "SET_DRAFT":
      return action.payload;
    case "PATCH_DRAFT":
      if (state === null) {
        // Cannot patch - draft isn't ready
        return state;
      }
      return { ...state, ...action.payload };
    default:
      throw new Error(`Unknown action: ${action}`);
  }
};

export default AssetFormPage;
