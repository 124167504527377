import * as React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Grid,
  CircularProgress,
  Hidden,
  FormControl,
  InputLabel,
  Chip,
  TextField,
  Paper,
  MenuItem,
} from "@material-ui/core";
import FaceIcon from "@material-ui/icons/Face";
import Autosuggest, { InputProps } from "react-autosuggest";

import { useDraft } from "../pages/AssetFormPage";
import { useLookupPeople, useLookupPerson, Person } from "../api";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: {
        position: "relative",
      },
      suggestionsContainerOpen: {
        position: "absolute",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        left: 0,
        zIndex: 100,
      },
      suggestion: {
        display: "block",
      },
      suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none",
      },
      chipWrapper: {
        marginTop: theme.spacing(2.5),
      },
    }),
  { name: "DraftPersonLookup" }
);

export interface DraftTextInputProps {
  name: string;
  label: string;
  value: string | null;
  halfWidth?: boolean;
  halfSpacer?: boolean;
  required?: boolean;
  helperText?: React.ReactNode;
}

/**
 * A wrapper that either shows the PersonLookup component or a Chip if a person
 * has already been selected.
 */
export const DraftPersonLookup = ({
  name,
  label,
  value,
  halfWidth = false,
  halfSpacer = false,
  required = true,
  helperText = "",
}: DraftTextInputProps) => {
  const [person, setPerson] = React.useState<Person | null>(null);
  const [loading, setLoading] = React.useState(true);
  const getPerson = useLookupPerson();
  const classes = useStyles();

  // when initially shown get the name for passed initial person
  React.useEffect(() => {
    if (loading) {
      if (value === null) {
        // no current value so no need to load person
        setLoading(false);
      } else {
        getPerson(value).then((person) => {
          if (person !== null) {
            setPerson(person);
          }
          setLoading(false);
        });
      }
    }
  }, [loading, value, setLoading, setPerson, getPerson]);

  const { patchDraft } = useDraft();
  // person selected in lookup component
  const handleSelect = (person: Person) => {
    setPerson(person);
    patchDraft({ [name]: person.id });
  };
  // current selected person cleared to show lookup component
  const handleDelete = () => {
    setPerson(null);
    patchDraft({ [name]: null });
  };

  return (
    <>
      <Grid item xs={12} md={halfWidth ? 6 : 12}>
        {!loading && !person && (
          <PersonLookup
            required={required}
            label={label}
            helperText={helperText}
            onSelect={handleSelect}
          />
        )}
        {(loading || person) && (
          <FormControl fullWidth>
            <InputLabel required={required} shrink={true}>
              {label}
            </InputLabel>
            <div className={classes.chipWrapper}>
              {loading && <CircularProgress size={20} />}
              {!loading && person && (
                <Chip
                  icon={<FaceIcon />}
                  onDelete={handleDelete}
                  variant="outlined"
                  label={`${person.name} (${person.id})`}
                />
              )}
            </div>
          </FormControl>
        )}
      </Grid>
      {halfSpacer ? (
        <Hidden smDown>
          <Grid item md={6} />
        </Hidden>
      ) : null}
    </>
  );
};

export interface PersonLookupProps {
  onSelect: (selected: Person) => void;
  required?: boolean;
  label: string;
  helperText?: React.ReactNode;
}

/**
 * Component that uses Autosuggest to show a TextField for entering the search text
 * and a list of suggestions retrieved from the lookup API
 */
const PersonLookup = ({ onSelect, required, label, helperText }: PersonLookupProps) => {
  const [suggestions, setSuggestions] = React.useState<Person[]>([]);
  const [searchText, setSearchText] = React.useState("");

  const getPeople = useLookupPeople();
  const classes = useStyles();

  // search text is changed, lookup suggestions if two or more trimmed characters
  const handleChange = (_: any, { newValue }: { newValue: string }) => {
    setSearchText(newValue);
    newValue = newValue.trim();
    if (newValue.length > 1) {
      getPeople(newValue).then((people) => {
        setSuggestions(people);
      });
    }
  };

  // suggestion selected, so pass back selection to wrapper above
  const handleSelect = (_: any, { suggestion }: { suggestion: Person }) => {
    // Bit of a hack to delay setting the suggestion to allow Autosuggest effects to finish
    setTimeout(() => {
      onSelect(suggestion);
    }, 100);
  };

  const inputProps: InputProps<Person> = {
    value: searchText,
    onChange: handleChange,
  };

  const renderInputComponent = (inputProps: InputProps<Person>) => {
    // Need to strip properties handled by TextField rather than Input
    const { color, onChange, ...rest } = inputProps;
    return (
      <TextField
        InputProps={{ ...rest }}
        fullWidth={true}
        required={required}
        label={label}
        helperText={helperText}
        onChange={(e) => {
          handleChange(e, { newValue: e.target.value });
        }}
      />
    );
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={() => {}}
      onSuggestionsClearRequested={() => setSuggestions([])}
      getSuggestionValue={(o) => o.id}
      onSuggestionSelected={handleSelect}
      shouldRenderSuggestions={(value: string) => value.trim().length > 1}
      multiSection={false}
      renderSuggestion={(suggestion, { isHighlighted }) => (
        <MenuItem selected={isHighlighted} component="div">
          {suggestion.name} ({suggestion.id})
        </MenuItem>
      )}
      renderSuggestionsContainer={({ containerProps, children }) => (
        <Paper {...containerProps} square>
          {children}
        </Paper>
      )}
      renderInputComponent={renderInputComponent}
      inputProps={inputProps}
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
    />
  );
};

export default DraftPersonLookup;
