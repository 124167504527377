import * as React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Grid, FormControl, InputLabel, Input } from "@material-ui/core";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      input: {
        color: "inherit !important",
      },
    }),
  { name: "ReadOnlyLabelValue" }
);

export interface ReadOnlyLabelValueProps {
  label: string;
  value: string | null;
  halfWidth?: boolean;
}

/**
 * A variant of Input which is, ironically, non-editable. It simply displays its value and label.
 */
export const ReadOnlyLabelValue = ({
  label,
  value,
  halfWidth = false,
}: ReadOnlyLabelValueProps) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={halfWidth ? 6 : 12}>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Input
          classes={{ disabled: classes.input }}
          value={value ? value : "Unanswered"}
          readOnly
          disabled
          disableUnderline
        />
      </FormControl>
    </Grid>
  );
};

export default ReadOnlyLabelValue;
