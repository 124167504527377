import * as React from "react";

import { Grid } from "@material-ui/core";
import { AssetViewSectionProps } from "../AssetView";
import { ReadOnlyLabelValue } from "./ReadOnlyLabelValue";

import { PURPOSE_LABELS } from "../../data";

const assetPurpose = (purpose: string | null) => {
  for (let p of PURPOSE_LABELS) {
    if (p.value === purpose) {
      return p.label;
    }
  }
  return purpose || "";
};

/**
 * Display general information section of asset view
 */
export const GeneralInformationView = ({ asset }: AssetViewSectionProps) => {
  return (
    <Grid container spacing={5}>
      <ReadOnlyLabelValue label="Asset name" value={asset.name} />

      <ReadOnlyLabelValue
        label="Asset department"
        value={asset.department ? (asset.instName ? asset.instName : "...") : null}
      />

      <ReadOnlyLabelValue
        halfWidth={true}
        label="Asset purpose"
        value={assetPurpose(asset.purpose)}
      />

      {
        // If the purpose is "research", display it
        asset.purpose === "research" ? (
          <ReadOnlyLabelValue
            halfWidth={true}
            label="Who is the Principal Investigator of this research activity?"
            value={asset.owner || ""}
          />
        ) : null
      }
      {
        // If the purpose is "other", display it
        asset.purpose === "other" ? (
          <ReadOnlyLabelValue
            halfWidth={true}
            label="Asset purpose"
            value={asset.purposeOther || ""}
          />
        ) : null
      }
    </Grid>
  );
};

export default GeneralInformationView;
