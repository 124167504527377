import * as React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  LinearProgress,
  Fade,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/CloudDownload";

import TooltipText from "./TooltipText";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      headLabel: {
        color: "rgba(0, 0, 0, 0.5)",
        fontWeight: theme.typography.body1.fontWeight,
        whiteSpace: "nowrap",
      },
      tableCell: {
        fontSize: "13px",
      },
      loadingContainerCell: {
        height: 0,
        position: "relative",
        margin: 0,
        padding: 0,
        border: "none",
      },
      loadingContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
      },
      actionCell: {
        width: 8 * 4,
      },
    }),
  { name: "AssetTableHeader" }
);

export interface AssetTableHeaderProps {
  isLoading: boolean;
  ordering: string | undefined;
  onSetOrdering: (ordering: string | null) => void;
  onDownload?: () => void;
}

interface SortCellProps {
  field: string;
  label: React.ReactNode;
  width: string | number;
}

type Direction = "asc" | "desc";

/**
 * Heading row for the list of asset summaries, with sortable fields
 */
export const AssetTableHeader = ({
  isLoading,
  onSetOrdering,
  ordering,
  onDownload,
}: AssetTableHeaderProps) => {
  const classes = useStyles();
  let direction: Direction = "asc";
  let sortField = "";
  if (ordering !== undefined) {
    if (ordering[0] === "-") {
      direction = "desc";
      sortField = ordering.substr(1);
    } else {
      sortField = ordering;
    }
  }

  const sortBy = (field: string) => {
    if (sortField === field && direction === "asc") {
      onSetOrdering(`-${field}`);
    } else {
      onSetOrdering(field);
    }
  };

  const SortCell = ({ field, label, width }: SortCellProps) => (
    <TableCell
      style={{ width: width }}
      className={classes.tableCell}
      sortDirection={sortField === field ? direction : false}
    >
      <TableSortLabel
        className={classes.headLabel}
        onClick={() => {
          sortBy(field);
        }}
        active={sortField === field}
        direction={direction}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  return (
    <TableHead>
      <TableRow>
        <SortCell width="50%" field="name" label="Name" />
        <SortCell
          width={8 * 10 + "px"}
          field="is_complete"
          label={
            <TooltipText title="Status">
              The status is automatically set to complete when the entry has a value in all
              required fields.
            </TooltipText>
          }
        />
        <SortCell width="50%" field="department" label="Institution" />
        <SortCell
          width={8 * 3 + "px"}
          field="private"
          label={
            <TooltipText title="Privacy">
              Only members of your institution can see 'Private' entries. Everyone can see 'Public'
              entries.
            </TooltipText>
          }
        />
        <SortCell width={8 * 13 + "px"} field="updated_at" label="Last edited" />
        <TableCell classes={{ root: classes.actionCell }}>
          {onDownload ? (
            <Tooltip title="Download assets" aria-label="download assets">
              <IconButton onClick={onDownload}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          ) : (
            "\u00A0"
          )}
        </TableCell>
      </TableRow>
      {isLoading && (
        <TableRow>
          <TableCell colSpan={6} className={classes.loadingContainerCell}>
            <div className={classes.loadingContainer}>
              <Fade in={isLoading}>
                <LinearProgress />
              </Fade>
            </div>
          </TableCell>
        </TableRow>
      )}
    </TableHead>
  );
};

export default AssetTableHeader;
