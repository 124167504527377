import { JSON_CONTENT_TYPE } from "./constants";

type DeleteRequestInit = RequestInit & { method: "DELETE" };

/**
 * A wrapper around fetch() which performs an API request. Returns a Promise which is resolved with
 * the decoded JSON body of the response (unless method is DELETE) or which is rejected in case of
 * an error.
 */
export async function apiFetch<ResponseBody = any>(
  input: string | Request,
  init: DeleteRequestInit,
  type?: string
): Promise<null>;
export async function apiFetch<ResponseBody = any>(
  input: string | Request,
  init?: Exclude<RequestInit, DeleteRequestInit>,
  type?: string
): Promise<ResponseBody>;
export async function apiFetch<ResponseBody = any>(
  input: string | Request,
  init: RequestInit = {},
  type: string = JSON_CONTENT_TYPE
): Promise<ResponseBody | null> {
  const response = await fetch(input, {
    credentials: "omit", // do not send any cookies or auth credentials with request
    ...init,
    headers: {
      ...init.headers,
      "Content-Type": type,
    },
  });

  if (!response || !response.ok) {
    const errorText = `API returned error response: ${response.statusText}`;
    const error = { error: new Error(errorText), response };

    // Try to parse the body of the error so that we can show it to the user
    return (
      responseBody(response, type)
        // If the response is unparsable then just return a generic error
        .catch(() => Promise.reject(error))
        .then((body) => Promise.reject({ ...error, body: body as ResponseBody }))
    );
  }

  // Parse response body as JSON (unless it was a delete).
  if (init.method === "DELETE") return null;

  return responseBody(response, type);
}

/**
 * Append to a URL's query string based on properties from the passed object.
 */
export const appendQuery = (endpoint: string, o: { [index: string]: any } = {}): string => {
  const url = new URL(endpoint);
  Object.keys(o).forEach((key) => {
    if (o[key] !== undefined) {
      if (Array.isArray(o[key])) {
        o[key].forEach((item: any) => url.searchParams.append(key, item));
      } else {
        url.searchParams.append(key, o[key]);
      }
    }
  });
  return url.href;
};

/**
 * Return a promise to either the json or text of a request based on content type
 */
const responseBody = (response: Response, type: string) =>
  type === JSON_CONTENT_TYPE ? response.json() : response.text();

export default apiFetch;
