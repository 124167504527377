import * as React from "react";

import { Box, Drawer, Hidden, useScrollTrigger } from "@material-ui/core";

import { makeStyles, createStyles, Theme, StyledComponentProps } from "@material-ui/core/styles";

import AuthenticationFlash from "../containers/AuthenticationFlash";
import SignInRequired from "../containers/SignInRequired";

import AppBar from "../components/AppBar";
import Sidebar from "../containers/Sidebar";

import { drawerWidth } from "../theme";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      // Styles applied to root element.
      root: {},

      // Add padding for elements below the drawer on sufficiently large screens.
      drawerPadded: {
        backgroundColor: theme.palette.primary.dark,
        [theme.breakpoints.up("lg")]: {
          paddingLeft: drawerWidth,
        },
      },

      drawerPaper: { width: drawerWidth },

      content: {
        backgroundColor: theme.palette.background.default,
      },

      contentFullPage: {
        backgroundColor: theme.palette.primary.dark,
      },

      toolbar: { ...theme.mixins.toolbar },
    }),
  { name: "GenericPage" }
);

export type GenericPageClassKey = keyof ReturnType<typeof useStyles>;

interface InnerPageProps extends StyledComponentProps<GenericPageClassKey> {
  appBarChildren?: React.ReactNode;
  children: React.ReactNode;
}

/**
 * Inner page not requiring sidebar
 */
const FullPage: React.FunctionComponent<InnerPageProps> = ({ appBarChildren, children }) => {
  const classes = useStyles();

  /* Shadow on app bar only appears when scrolling */
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <>
      <AppBar
        position="fixed"
        classes={{ root: classes.contentFullPage }}
        elevation={trigger ? 4 : 0}
        children={appBarChildren}
      />
      <div className={classes.contentFullPage}>
        {/* used as a spacer */}
        <div className={classes.toolbar} />
        {children}
      </div>
    </>
  );
};

/**
 * Inner page requiring sidebar
 */
const SidebarPage: React.FunctionComponent<InnerPageProps> = ({ appBarChildren, children }) => {
  const classes = useStyles();

  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const handleDrawerToggle = () => setMobileDrawerOpen((prev) => !prev);

  return (
    <>
      <AppBar
        position="fixed"
        classes={{ root: classes.drawerPadded }}
        onMenuClick={handleDrawerToggle}
        children={appBarChildren}
      />

      <Hidden lgUp={true}>
        <Drawer
          variant="temporary"
          open={mobileDrawerOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Sidebar />
        </Drawer>
      </Hidden>

      <Hidden mdDown={true} implementation="css">
        <Drawer
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Sidebar />
        </Drawer>
      </Hidden>

      <Box minHeight="100vh">
        <div className={classes.drawerPadded}>
          {/* used as a spacer */}
          <div className={classes.toolbar} />

          <div className={classes.content}>{children}</div>
        </div>
      </Box>
    </>
  );
};

/**
 * A top level component which wraps all pages.
 */
export interface GenericPageProps extends StyledComponentProps<GenericPageClassKey> {
  authRequired?: Boolean;
  withSidebar?: Boolean;
  appBarChildren?: React.ReactNode;
  children: React.ReactNode;
}

export const GenericPage: React.FunctionComponent<GenericPageProps> = ({
  appBarChildren,
  children,
  authRequired = true,
  withSidebar = true,
}) => {
  const classes = useStyles();

  const innerPage = withSidebar ? (
    <SidebarPage appBarChildren={appBarChildren} children={children} />
  ) : (
    <FullPage appBarChildren={appBarChildren} children={children} />
  );

  return (
    <div className={classes.root}>
      {authRequired && (
        <>
          <AuthenticationFlash />
          <SignInRequired children={innerPage} />
        </>
      )}
      {!authRequired && innerPage}
    </div>
  );
};

export default GenericPage;
