import * as React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Paper, Box, CircularProgress } from "@material-ui/core";

import AssetSection from "./AssetSection";
import {
  GeneralInformationView,
  PersonalDataView,
  RisksView,
  StorageAndSecurityView,
} from "./assetviewsections";
import { Asset } from "../api";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      paper: {
        minHeight: 1000, // enlarge white paper while loading
        fontSize: theme.typography.body1.fontSize,
      },
    }),
  { name: "AssetView" }
);

// properties of AssetView component
export interface AssetViewProps {
  isLoading: boolean;
  asset: Asset | null;
}

// properties of section components of the AssetView
export interface AssetViewSectionProps {
  asset: Asset;
}

/**
 * Body of asset summaries list containing each asset item or a notification when
 * no assets are available
 */
export const AssetView = ({ asset, isLoading }: AssetViewProps) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      {isLoading && (
        <Box textAlign="center" pt={10}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && asset && (
        <>
          <AssetSection title="General information">
            <GeneralInformationView asset={asset} />
          </AssetSection>
          <AssetSection title="Personal data">
            <PersonalDataView asset={asset} />
          </AssetSection>
          <AssetSection title="Risks">
            <RisksView asset={asset} />
          </AssetSection>
          <AssetSection title="Storage & Security">
            <StorageAndSecurityView asset={asset} />
          </AssetSection>
        </>
      )}
    </Paper>
  );
};

export default AssetView;
