/**
 * This page displays a sortable,infinitely scrolling list of asset summaries,
 * optionally filtered by the institution selected in the sidebar.
 */
import * as React from "react";

import { Box, Table } from "@material-ui/core";
import { useParams } from "react-router-dom";

import { GenericPage } from ".";
import AssetListHeader from "../components/AssetListHeader";
import AssetTableHeader from "../components/AssetTableHeader";
import { AssetTableBody, AssetSummary } from "../components/AssetTableBody";
import GetMoreAssets from "../components/GetMoreAssets";
import DeleteConfirmation from "../components/DeleteConfirmation";

import { useLookupInstName, useAssets, Asset } from "../api";

export const AssetListPage: React.FunctionComponent = () => {
  const { instId } = useParams();
  const nameLookup = useLookupInstName();
  const {
    setDepartment,
    isLoading,
    setOrdering,
    nextPage,
    assets,
    assetIds,
    query,
    deleteAsset,
    dumpAssets,
    next,
  } = useAssets();

  // Whether to confirm deletion (by id)
  const [idToDelete, setIdToDelete] = React.useState<string | null>(null);
  const performDelete = React.useCallback(() => {
    if (idToDelete && idToDelete in assets) {
      const asset = assets[idToDelete];
      // clear confirmation
      setIdToDelete(null);
      // call deletion API
      deleteAsset(asset);
    }
  }, [idToDelete, setIdToDelete, assets, deleteAsset]);

  // Trigger fetch filtered by department on first render for an instId
  React.useEffect(() => {
    setDepartment(instId ? instId : null);
  }, [instId, setDepartment]);

  const appBar = (
    <AssetListHeader title={"Assets: " + (instId ? nameLookup(instId) : "All institutions")} />
  );

  // convert full Asset response in to summary needed for each row of table
  const assetToSummary = (asset: Asset): AssetSummary => {
    const { id, name, isComplete, department, updatedAt, allowedMethods } = asset;
    return {
      id,
      name,
      isComplete,
      department,
      isPrivate: !!asset["private"],
      updatedAt,
      allowedMethods,
      instName: nameLookup(department || ""),
    };
  };
  const summaries = assetIds.map((asset) => assetToSummary(assets[asset]));

  return (
    <GenericPage appBarChildren={appBar}>
      <Box px={4} py={1}>
        <Table size="small">
          <AssetTableHeader
            isLoading={isLoading}
            onSetOrdering={setOrdering}
            ordering={query.ordering}
            onDownload={() => {
              dumpAssets(instId || null);
            }}
          />
          <AssetTableBody
            summaries={summaries}
            isLoading={isLoading}
            onDelete={(id: string) => {
              setIdToDelete(id);
            }}
          />
        </Table>
        {assetIds.length > 0 && next !== null && (
          <GetMoreAssets onNextPage={nextPage} isLoading={isLoading} />
        )}
      </Box>
      <DeleteConfirmation
        open={idToDelete !== null}
        assetName={idToDelete && idToDelete in assets ? assets[idToDelete].name : null}
        onClose={() => {
          setIdToDelete(null);
        }}
        onDelete={performDelete}
      />
    </GenericPage>
  );
};

export default AssetListPage;
